<template>
    <div>
       
        <v-card >
            <v-row>

                
                <v-col md="12" cols="12">
                    <v-card-title>#ACTS4BEIRUT</v-card-title>

                    <v-card-subtitle >
                     
                        Dear expats, supporters, creatives, Café, cinema or venue managers & teams! <br><br>

LI BEIRUT is a global portal for solidarity, commemoration and exchange for the Beirut Port Explosion. <br>

Launching an action map and live stream broadcasting platform will give supporters around the world the opportunity to create commemorative events and open thinking hubs to change the situation in Beirut, while being matched to initiatives in the city that are developing sustainable and impactful solutions and are still seeking independent support. 
<br><br>
We are asking YOU to get creative LI (for) BEIRUT. <br>
The portal will open the door for small hubs and events all over the world to be created. <br>

You can find more information about the procedure and what you can do here:<br>
<a href="https://ardkon.s3.eu-central-1.amazonaws.com/helo/action portal LI BEIRUT Sep 21.pdf" target="_Blank">click here</a>

<br><br>Register here to:
<br>
<ul>
    <li>
        create a team and volunteering community to initiate a LI BEIRUT event from scratch
    </li>
    <li>pin a planned event (concert, gastronomy etc.) on the map </li>
    <li>
        inform us about your/the event community interests so we can match you with the 
    </li>
</ul>
<br><br>

<span class="font-weight-bold">Please answer the following questions to create your LI BEIRUT event(s):</span> </v-card-subtitle>

                </v-col>
                
             
                
            </v-row>
                  
            <v-card-text>

              <v-card-text>
                <v-row>
                    <v-col cols="12" md="6">
                        <h3>select date:</h3>
                        <v-date-picker v-model="form.act_information.act_date"></v-date-picker>

                    </v-col><v-col cols="12" md="6">
                        <h4>in which country do you want to act LI BEIRUT?</h4>
                            <country-select
                                v-model="form.act_information.act_creator_country"
                                class="v-label v-input__slot pt-6 text-lowercase"
                                label="in which country do you want to act LI BEIRUT?"
                                :country="form.act_information.act_creator_country"
                                topCountry="LB"
                            />  
                            <v-select
                                label="we are planning...  "
                                :items="planning_phase"
                                v-model="form.act_information.planning_phase"
                                data-vv-name="select"
                                required
                            ></v-select>  
                            <v-select
                                label="Do you know want to collaborate with and address a certain community? "
                                :items="collaboration_community"
                                v-model="form.act_information.collaboration_communitys"
                                data-vv-name="select"
                                required
                            ></v-select>
                            <v-text-field
                                v-if="form.act_information.collaboration_communitys === 'other'"
                                label="specifications, if needed"
                                v-model="form.act_information.collaboration_community"
                                required
                            ></v-text-field>
                    </v-col>
                </v-row>
                <v-text-field
                                v-model="form.act_information.act_name"
                                label="short title of your act"
                                required
                            ></v-text-field>
                           
                           
                            <v-textarea
                                label="Tell us about your ideas:"
                                required
                                rows="2"
                                v-model="form.act_information.act_idea"
                                clearable
                                clear-icon="mdi-close-circle"
                            ></v-textarea>
                            <h3>
                                We can ask for collaborators and volunteers on the platform and ardkon channels. 
                            </h3>
                            <v-textarea
                                label="short text for a call for volunteers from your side:"
                                required
                                rows="2"
                                v-model="form.act_information.act_volunteer_short_text"
                                clearable
                                clear-icon="mdi-close-circle"
                            ></v-textarea>
                            <v-text-field
                                v-model="form.act_information.act_volunteer_contact"
                                label="contact"
                                required
                            ></v-text-field>
              </v-card-text>
                            
                           
                            
                         
                            
            </v-card-text>
            <v-card-text>
                <v-row>
                    <v-col md="6" cols="12">
                        <v-card-text>
                            <v-text-field  @input="$v.venue_name.$touch()" v-model="form.venue_name" label="venue name" required></v-text-field>
                            <v-text-field
                                v-model="form.venue_street"
                                label="street"
                                required
                            ></v-text-field>
                        </v-card-text>
                    </v-col>
                    <v-col md="6" cols="12">
                        <v-card-text>
                            <v-text-field
                                v-model="form.venue_number"
                                type="tel"
                                label="phone number"
                                required
                            ></v-text-field>
                            <country-select
                                v-model="form.venue_country"
                                class="v-label v-input__slot pt-6 text-lowercase"
                                :country="form.venue_country"
                                topCountry="LB"
                            />
                            <hr />
                            <v-text-field
                                v-model="form.venue_city"
                                type="text"
                                label="city"
                                required
                            ></v-text-field>
                        </v-card-text>
                    </v-col>
                    <v-col md="6" cols="12">
                        <v-card-text>
                            <v-text-field
                                label="google maps link"
                                v-model="form.google_maps_link"
                                required
                            ></v-text-field>
                            <v-text-field
                                label="social media or website link"
                                v-model="form.social_media_link"
                                required
                            ></v-text-field>
                        </v-card-text>
                    </v-col>
                    <v-col md="6" cols="12">
                        <v-card-text>
                           <p>
                            add your logo
                           </p> 
                            <v-file-input
                                label="File input"
                                filled
                                v-model="form_1.logo"
                                prepend-icon="mdi-camera"
                                accept="image/png, image/jpg, image/jpeg"
                                @change="imageToBase64"
                            ></v-file-input>
                            <span class="success--text">{{ url_message }}</span>
                        
                            
                                <p>the place or poster of your event</p>
                            
                            <v-file-input
                                label="File input"
                                filled
                                v-model="form_1.poster"
                                prepend-icon="mdi-camera"
                                accept="image/png, image/jpg, image/jpeg"
                                @change="imageToBase641"
                            ></v-file-input>
                            <span class="success--text">{{ poster_message }}</span>
                        </v-card-text>
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-card-text>
                            <v-select
                                label="my program ideas:"
                                :items="ideas"
                                v-model="form.program_ideas"
                                data-vv-name="select"
                                required
                            ></v-select>
                            <v-text-field
                                v-if="form.program_ideas === 'other'"
                                label="add your program idea"
                                v-model="form.program_idea"
                                required
                            ></v-text-field>
                        </v-card-text>
                    </v-col>
                    <v-col cols="12">
                        <v-card-text>
                            A short sentence to our customers and those who view the action on the LI BEIRUT portal:<br>
                            <v-textarea
                                label="short description"
                                required
                                rows="2"
                                v-model="form.short_description"
                                clearable
                                clear-icon="mdi-close-circle"
                            ></v-textarea>
                        </v-card-text>
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-card-text>
                            How are you planning to support the initiatives?<br>
                            <v-select
                                label="we are planning...  "
                                :items="donations_type"
                                v-model="form.donations_type"
                                data-vv-name="select"
                                required
                            ></v-select>
                            <v-text-field
                                v-if="form.donations_type === 'other'"
                                label="specifications, if needed"
                                v-model="form.donation_type"
                                required
                            ></v-text-field>
                        </v-card-text>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-card-text>The form in which business owners want to dedicate returns of the campaign event is their own decision and will not be influenced by ardkon or the campaign partners. We’ll be getting in contact with you after you submitted the form and agree on the details. </v-card-text>
                        <v-card-text>
                            Which kind of initiatives would you like to reward with your donations for their efforts of the last year and for them to keep supporting the people of Lebanon
                            <br>
                            Do you want to support an initiative that is not on our list yet? Mention them here so we can introduce them to the registration (link to registration form initiatives) as an initiative! <br>
                            <v-select
                                label="initiative type"
                                :items="initiative_types"
                                v-model="form.initiative_types"
                                data-vv-name="select"
                                required
                            ></v-select>
                            <v-text-field
                                v-if="form.initiative_types === 'other'"
                                label="specifications, if needed"
                                v-model="form.initiative_type"
                                required
                            ></v-text-field>
                        </v-card-text>

                    </v-col>
                    <v-col cols="12">
                        <v-card-title>PERSONAL CONTACT INFORMATION (for the internal coordination)</v-card-title>
                        <v-card-text>
                            <v-text-field label="name" v-model="form.name" required></v-text-field>
                            <v-text-field
                                label="email address"
                                v-model="form.email_address"
                                required
                            ></v-text-field>
                            <v-text-field label="address" v-model="form.address" required></v-text-field>

                            <v-select
                                label="What’s your personal connection to the story of Beirut?"
                                :items="connections"
                                v-model="form.connections"
                                data-vv-name="select"
                                required
                            ></v-select>
                        </v-card-text>
                    </v-col>
                    <v-col md="12" cols="12">
                        <v-card-subtitle>
                            <v-checkbox
                                class="caption"
                                v-model="checkbox"
                                small
                                label="When submitting this form, I am agreeing that the given information about my venue business & the planned events will be displayed on the action map, shared for the campaign and by its partners. ardkon is applying their privacy policy.
ardkon.com is not the initiator of the individual events within the campaign and cannot be held responsible for them nor the content provided by their participants.
"
                            ></v-checkbox>
                        </v-card-subtitle>
                        <v-card-subtitle
                            class="caption"
                        >Thank you for participating with a small act. You will receive an email with the further details shortly.</v-card-subtitle>
                        <br />
                        <span class="red--text">{{ message }}</span>
                        <br />
                    </v-col>
                </v-row>

                <v-progress-linear v-if="loadingBar" color="info accent-4" indeterminate rounded height="6"></v-progress-linear>
                <v-row>
                    <v-col md="12">
                    
                        <v-card-text>
                           


contact us if you have any questions: dorothea@ardkon.com
<br>
ardkon.com & partners                        </v-card-text>
                    </v-col>
                </v-row>
                <v-card-text>
                    <span class="error--text">{{message_error}}</span><br>
                    <v-btn class="mr-4"  color="primary" :disabled="checkbox ===  false"  @click="submit">submit</v-btn>
                </v-card-text>
                
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import LiBeirutImg from '@/assets/LiBeirut.jpeg'
import LiBeirutImg1 from '@/assets/LiBeirut-D.jpeg'
import LiBeirutImg2 from '@/assets/poster_action_map_.jpg'
import LiBeirutImg1122 from '@/assets/LiBeirut1122.jpeg'

import { required, minLength, between } from 'vuelidate/lib/validators'

import store from '@/store'
export default {
    name: 'li-beirut-form-1',
    data() {
        return {
            ideas: [
                'I am simply dedicating the day in thoughts of the Beirut blast and want to make my customers aware ', 'I am planning a small concert', 'The kitchen / volunteers are preparing Lebanese dishes', 'We will screen a movie', 'There will be a lecture/ panel discussions', 'other'
            ], donations_type: [
                'to dedicate the ticket price (or a percentage) ', 'to donate a part of the profits of the day ', 'to dedicate a special dish, drink or item', 'other'
            ],
            connections: ['i am Lebanese myself', 'i have direct friends & connections there and want to support', 'i just chose to stand in solidarity'],
            initiative_types: ['reconstruction', 'health', 'youth and children', 'animal welfare', 'LGBTQI*', ' sustainable infrastructure', 'art and culture',  'other'],
            img: LiBeirutImg,
            collaboration_community:['university students', 'Lebanese expats in particular', 'other'],
            img1: LiBeirutImg1,
            img2: LiBeirutImg2,
            img123: LiBeirutImg1122,
            checkbox: false,
            planning_phase: [' i want to initiate something new and am still searching for collaborators ', 'I have a group that i am working with / I am managing the involved'],
            form_1: {
                logo: {},
                logo_url: '',
                poster:{},
                poster_url:''
            },
            message_error:'',
            form: {
                act_information:{
                    act_name: '',
                    act_date:'',
                    planning_phase:'',
                    collaboration_contact:'',
                    act_idea:'',
                    act_volunteer_short_text: '',
                    act_volunteer_contact: '',
                    act_creator_country:'',
                    collaboration_community: '',
                    collaboration_communitys:''
                },
                venue_name: '',
                venue_street: '',
                venue_number: '',
                venue_country: '',
                venue_city: '',
                short_description:'',
                logo_url:'',
                poster_url:'',
                google_maps_link: '',
                program_ideas: '',
                program_idea: '',
                donation_type: '',
                donations_type: '',
                initiative_types:'',
                initiative_type:'',
                connections: '',
                name:'',
                email_address:'',
                address: '',
                social_media_link:''


            }

        }

    },
    validations: {
        venue_name: {
            required,
            minLength: minLength(4)
        },
        age: {
            between: between(20, 30)
        }
    },
    computed: {
        message(){
            return store.state.forms.message_form
        },
        loadingBar(){
            return store.state.forms.loading_bar
        },
        url_message() {
            return store.state.forms.message
        },poster_message() {
            return store.state.forms.message_poster
        },
        venue_img_url() {
            return store.state.forms.img_uploaded
        },
        venue_poster_url() {
            return store.state.forms.poster_uploaded
        }

    },
    methods: {

        async check(){
            store.dispatch('forms/loadingTrue')
            this.form.logo_url = this.venue_img_url
            this.form.poster_url = this.venue_poster_url
            if(this.form.program_idea === ''){
                this.form.program_idea = this.form.program_ideas
            } 
            
            if(this.form.collaboration_community === ''){
                this.form.collaboration_community = this.form.collaboration_communitys
            }
            if(this.form.donation_type === ''){
                this.form.donation_type = this.form.donations_type
            }
            if(this.form.initiative_type === ''){
                this.form.initiative_type = this.form.initiative_types
            }

            return true
        },
       async submit(){

            this.check().then((result) =>{
                console.log(result)
                if( this.form.act_information.act_date != '' & this.form.venue_name != '' & this.form.venue_street != '' &  this.form.venue_number != '' &   this.form.venue_country != '' & this.form.short_description != '' & this.form.logo_url != '' & this.form.poster_url != '' & this.form.google_maps_link != '' &        this.form.program_idea != '' & this.form.donation_type != '' & this.form.initiative_type !='' & this.form.connections != '' & this.form.name != '' & this.form.email_address != '' &      this.form.address != '' &   this.form.social_media_link != ''){
                store.dispatch('forms/liBeirutForm', this.form)
                this.message_error = ""


            } else {
                store.dispatch('forms/loadingFalse')

                if(this.form.venue_name === ''){
                    this.message_error = "add venue name"

                } if(this.form.act_information.act_date === ''){
                    this.message_error = "add act date"

                } 
                if(this.form.venue_street === ''){
                    this.message_error = "add venue street"

                } if(this.form.venue_number === ''){
                    this.message_error = "add venue number"

                }
                if(this.form.venue_country === ''){
                    this.message_error = "add venue country"

                }if(this.form.short_description === ''){
                    this.message_error = "add venue short description"

                }if(this.form.logo_url === ''){
                    this.message_error = "add venue logo"

                }if(this.form.poster_url === ''){
                    this.message_error = "add venue poster or place picture"

                }if(this.form.google_maps_link === ''){
                    this.message_error = "add venue poster or google maps link"

                }if(this.form.program_idea === ''){
                    this.message_error = "add venue program idea"

                }if(this.form.donation_type === ''){
                    this.message_error = "add donation type"

                }if(this.form.initiative_type === ''){
                    this.message_error = "add initiative type"

                }if(this.form.connections === ''){
                    this.message_error = "add connections"

                }if(this.form.name === ''){
                    this.message_error = "add name"

                }if(this.form.email_address === ''){
                    this.message_error = "add email address"

                }if(this.form.address === ''){
                    this.message_error = "add address"

                }if(this.form.social_media_link === ''){
                    this.message_error = "add social media link"

                }

            }
            })
           
            console.log(this.form)

           


        },
        imageToBase64() {
            var reader = new FileReader()
            reader.readAsDataURL(this.form_1.logo)
            reader.onload = () => {


                this.form_1.logo_url = reader.result.split(',')[1]
                store.dispatch('forms/uploadImage', { image: this.form_1.logo_url })

            }
            reader.onerror = function(error) {
                console.log('Error: ', error)

            }
        }, imageToBase641() {
            var reader = new FileReader()
            reader.readAsDataURL(this.form_1.poster)
            reader.onload = () => {


                this.form_1.poster_url = reader.result.split(',')[1]
                store.dispatch('forms/uploadImage1', { image: this.form_1.poster_url })

            }
            reader.onerror = function(error) {
                console.log('Error: ', error)

            }
        }
    }
}
</script>