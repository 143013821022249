import axios from 'axios'
import localforage from 'localforage';
var multiForage = require("multiforage")(localforage);


const actions = {
    async setDonationCampaignInitiative({ commit }, form) {

        multiForage.getItems(['access_token', 'email_address'], function (err, items) {
            form.access_token = items.access_token
            form.email_address = items.email_address
            //todo remember to change api address
            return axios.post('https://apiss.ardkon.com/doanteCampaign?basics=donation', form).then((res) => {

                //dispatch('getDonationTotalModerator', { })
                commit('SET_DONATION')
                console.log(res)
            })
        })

    }, async getDonationsCampaign({ commit }, form) {

        console.log('arrived here')
        
            //todo remember to change api address
            return axios.post('https://apiss.ardkon.com/GetTransactionsCampaign?basics=donation', form).then((res) => {

                //dispatch('getDonationTotalModerator', { })
                commit('SET_DONATIONS_CAMPAIGN', res.data.data.transactions);
                console.log(res)
         
        });

    },
    async getStreams({  commit }, form) {

        return   axios
                        .post('https://apiss.ardkon.com/BrGetDetails?basics=campaign2', form)
                        .then(response => {
    
                            console.log(response)
                           commit('SET_STREAMS', response.data.data.acts_details)
                           commit('SET_STREAM', response.data.data.acts_details[0].properties)
    
    
    
    
                        }).catch(error => {
                              console.log(error)
                          });
        },

    setStream({commit}, data){
        commit('SET_STREAM',data)
    }
}

const mutations = {
    STREAMS(state, data){
        state.online_streams.push(data)
    },
    SET_STREAM(state,data){
        state.active_stream = data
    },
    SET_DONATIONS_CAMPAIGN(state, data){
        for(var i = 0; i<data.length; i++){
            state.transactions.push(data[i])
        }
    }
   
}

const donations ={
    namespaced: true,
    state:{
       transactions:[]
    },
    mutations,
    actions
}

export default donations;