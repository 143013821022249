import Vue from 'vue'
import Vuex from 'vuex'
import forms from './forms'
import streaming from './streaming'
import partners from './partners'
import venues from './venues'
import donations from './donations'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    forms,
    streaming,
    partners,
    venues,
    donations

  }
})
