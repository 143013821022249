
<template>
    <div>
       
        <v-card class="mx-auto card-transparent" max-width="900">
            
           
            <v-card-title>Initiative Sign up Process</v-card-title>
                <v-row>
                    <v-col md="6" cols="12">
                        <v-card-text>
                            <v-text-field v-model="form.init_name" label="Initiative Name" required></v-text-field>
                            <v-text-field v-model="form.username" label="username" required></v-text-field>
                            <v-text-field v-model="form.password" type="password" label="password" required></v-text-field>
                           
                        </v-card-text>  
                    </v-col>
                    <v-col md="6" cols="12">
                        <v-card-text>
                            <v-textarea
                                v-model="form.init_description"
                                color="teal"
                                >
                                <template v-slot:label>
                                    <div>
                                        Initiative Description
                                    </div>
                                </template>
                            </v-textarea>
                            <v-text-field
                                v-model="form.init_website"
                                color="teal"
                                >
                                <template v-slot:label>
                                    <div>
                                        Website <small>(optional)</small>
                                    </div>
                                </template>
                            </v-text-field>
                        </v-card-text>
                    </v-col>
                    <v-col md="6" cols="12">
                        <v-card-text>
                            <country-select
                                v-model="form.init_country"
                                class="v-label v-input__slot pt-6"
                                :country="form.init_country"
                                topCountry="LB"
                            /><hr><br>
                            <v-text-field
                                v-model="form.init_city"
                                color="teal"
                                >
                                <template v-slot:label>
                                    <div>
                                        Initiative City <small>(optional)</small>
                                    </div>
                                </template>
                            </v-text-field>
                        </v-card-text>
                    </v-col>
                    <v-col md="6" cols="12">
                        <v-card-text>
                           <p class=" mx-3 subtitle dark-grey--text">
                            Add your Logo
                           </p> 
                            <v-file-input
                                label="File input"
                                filled
                                v-model="form_1.logo"
                                prepend-icon="mdi-camera"
                                accept="image/png, image/jpg, image/jpeg"
                                @change="imageToBase64"
                            ></v-file-input>
                            <span class="success--text">{{ url_message }}</span>
                        </v-card-text>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-card-text>
                            We would like to commend you for taking a step in helping the citizens of beirut affected by the August 4th blast. Thank you for your effort and support.<br>Please select the type of your initiative.<br>

                            <v-select
                                label="Initiative Type"
                                :items="init_types"
                                v-model="form.init_types"
                                data-vv-name="select"
                                required
                            ></v-select>
                            <v-text-field
                                v-if="form.init_types === 'other'"
                                label="specifications, if needed"
                                v-model="form.init_type"
                                required
                            ></v-text-field>
                        </v-card-text>

                        
                    </v-col>
                    
                    <v-col md="12" cols="12">
                        <v-card-subtitle>
                            <v-checkbox
                                class="caption"
                                v-model="checkbox"
                                small
                                label="When submitting this form, I am agreeing that the given information about my initiative will be displayed on the initiative list, shared for the campaign and by its partners. ardkon is applying their privacy policy "
                    ></v-checkbox>
                        </v-card-subtitle>
                        <span class="red--text">{{ message }}</span>
                    </v-col>
                    
                </v-row>

    
                <v-card-text>
                    <v-progress-linear v-if="loadingBar" color="info accent-4" indeterminate rounded height="6"></v-progress-linear>

                    <span class="error--text">{{message_error}}</span><br>
                    <v-btn class="mr-4"  color="primary" :disabled="checkbox ===  false"  @click="submit">submit</v-btn>
                </v-card-text>
                
        </v-card>
    </div>
</template>
<script>
import store from '@/store'
export default {
    name: 'initiative-sign-in',
    data() {
        return {
            
            init_types: ['reconstruction', 'medical support', 'psychological support', 'food support', 'other'],
            checkbox: false,
            form_1: {
                logo: {},
                init_logo_url: ''
            },
            message_error:'',
            form: {
                init_name: '',
                username: '',
                password: '',
                init_country: '',
                init_city:'',
                init_description:'',
                init_logo_url:'',
                init_types:'',
                init_type:'',
                init_website:'',
                init_founding_date:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                campaign_id:"4644c74b-8afc-4a9a-b355-e89d7c06914b",
            },
            
            menu: false,
        }
    },
    computed: {
        message(){
            return store.state.forms.message_form
        },
        loadingBar(){
            return store.state.forms.loading_bar
        },
        url_message() {
            return store.state.forms.message
        },
        initiative_img_url() {
            return store.state.forms.img_uploaded
        }
    },
    methods: {
        async check(){
            store.dispatch('forms/loadingTrue')
            this.form.init_logo_url = this.initiative_img_url
            if(this.form.init_type === ''){
                this.form.init_type = this.form.init_types
            }
            return true
        },
       async submit(){
            this.check().then((result) =>{
                console.log(result)
                if(this.form.init_name != '' & this.form.username != '' & this.form.password != '' & this.form.init_description != '' &   this.form.init_country != '' & this.form.init_logo_url != ''      & this.form.init_type !=''){
                store.dispatch('forms/initiativeRegistration', this.form)
                this.message_error = ""
            } else {
                store.dispatch('forms/loadingFalse')
                if(this.form.init_name === ''){
                    this.message_error = "add initiative name"
                }if(this.form.username === ''){
                    this.message_error = "add username"
                }if(this.form.password === ''){
                    this.message_error = "add password"
                }if(this.form.init_country === ''){
                    this.message_error = "add initiative country"
                }if(this.form.init_description === ''){
                    this.message_error = "add initiative short description"
                }if(this.form.init_logo_url === ''){
                    this.message_error = "add initiative logo"
                }if(this.form.init_type === ''){
                    this.message_error = "add initiative type"
                }
            }
            })
           
            console.log(this.form)
           
        },
        imageToBase64() {
            var reader = new FileReader()
            reader.readAsDataURL(this.form_1.logo)
            reader.onload = () => {
                this.form_1.init_logo_url = reader.result.split(',')[1]
                store.dispatch('forms/uploadImage', { image: this.form_1.init_logo_url })
            }
            reader.onerror = function(error) {
                console.log('Error: ', error)
            }
        },
    }
}
</script>

