<template>
    <div>
        <v-app-bar app color="primary" dark>
            <h3>add your donated Item</h3>
        </v-app-bar>
        <v-card class="ma-3 card-transparent">
            <v-row>

                
                <v-col md="6" cols="12">
                    <v-card-title class="ma-4">#ACTS4BEIRUT</v-card-title>

                    <v-card-subtitle class="ma-4">
                      
                    </v-card-subtitle>

                </v-col>
                
               <v-col md="6"  cols="12" class="my-auto">
                   <v-img    src="https://ard-platform.s3.eu-central-1.amazonaws.com/profile-members/hero-to-back-1626381522570.png"></v-img>
               </v-col>
               
                
            </v-row>

            <v-card-text class="text-center"></v-card-text>
            <v-card-title>information about the venue:</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col md="6" cols="12">
                        <v-select
                                label="donation type"
                                :items="types"
                                v-model="form.type"
                                data-vv-name="select"
                                required
                            ></v-select>
                        <v-card-text>
                            <v-text-field v-if="form.type === 'item donation'"  v-model="form.item_name" label="item name" required></v-text-field>
                            <v-text-field
                                v-model.number="form.item_price"
                                type="number"

                                label="item price"
                                required
                            ></v-text-field>
                        </v-card-text>
                    </v-col>
                    <v-col md="6" cols="12">
                        <v-card-text>
                            <v-text-field
                                v-model.number="form.quantity"
                                type="number"
                                label="phone number"
                                required
                            ></v-text-field>

                           
                        </v-card-text>
                    </v-col>
                   
                    <v-col md="6" cols="12">
                        <v-card-text>
                           <p>
                            add your logo
                           </p> 
                            <v-file-input
                                label="File input"
                                filled
                                v-model="form_1.logo"
                                prepend-icon="mdi-camera"
                                accept="image/png, image/jpg, image/jpeg"
                                @change="imageToBase64"
                            ></v-file-input>
                            <span class="success--text">{{ url_message }}</span>
                        
                            
                                <p>the place or poster of your event</p>
                            
                            <v-file-input
                                label="File input"
                                filled
                                v-model="form_1.poster"
                                prepend-icon="mdi-camera"
                                accept="image/png, image/jpg, image/jpeg"
                                @change="imageToBase641"
                            ></v-file-input>
                            <span class="success--text">{{ poster_message }}</span>
                        </v-card-text>
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-card-text>
                            <v-select
                                label="my program ideas for august 4 "
                                :items="ideas"
                                v-model="form.program_ideas"
                                data-vv-name="select"
                                required
                            ></v-select>
                            <v-text-field
                                v-if="form.program_ideas === 'other'"
                                label="add your program idea"
                                v-model="form.program_idea"
                                required
                            ></v-text-field>
                        </v-card-text>
                    </v-col>
                    <v-col cols="12">
                        <v-card-text>
                            A short sentence to our customers and those who view the action on the libeirut map about what and why we are preparing for August 4:<br>
                            <v-textarea
                                label="short description"
                                required
                                rows="2"
                                v-model="form.short_description"
                                clearable
                                clear-icon="mdi-close-circle"
                            ></v-textarea>
                        </v-card-text>
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-card-text>
                            our place is planning to ask for donations in the following form<br>
                            <v-select
                                label="donation ask type"
                                :items="donations_type"
                                v-model="form.donations_type"
                                data-vv-name="select"
                                required
                            ></v-select>
                            <v-text-field
                                v-if="form.donations_type === 'other'"
                                label="specifications, if needed"
                                v-model="form.donation_type"
                                required
                            ></v-text-field>
                        </v-card-text>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-card-text>The form in which business owners want to dedicate returns of the campaign event is their own decision and will not be influenced by ardkon or the campaign partners.  We’ll be getting in contact with you after you submitted the form and agree on the details. </v-card-text>
                        <v-card-text>
                            Which kind of initiatives would you like to reward with your donations for their efforts of the last year and for them to keep supporting the people of Lebanon<br>

                            <v-select
                                label="initiative type"
                                :items="initiative_types"
                                v-model="form.initiative_types"
                                data-vv-name="select"
                                required
                            ></v-select>
                            <v-text-field
                                v-if="form.initiative_types === 'other'"
                                label="specifications, if needed"
                                v-model="form.initiative_type"
                                required
                            ></v-text-field>
                        </v-card-text>

                        <v-card-text class="h4">Until August 4 we will gather more initiatives on the platform for you to grant your support and get informed about their work and how the funds are being facilitated.</v-card-text>
                    </v-col>
                    <v-col cols="12">
                        <v-card-title>personal contact information</v-card-title>
                        <v-card-text>
                            <v-text-field label="name" v-model="form.name" required></v-text-field>
                            <v-text-field
                                label="email address"
                                v-model="form.email_address"
                                required
                            ></v-text-field>
                            <v-text-field label="address" v-model="form.address" required></v-text-field>

                           
                        </v-card-text>
                    </v-col>
                    <v-col md="12" cols="12">
                        <v-card-subtitle>
                            <v-checkbox
                                class="caption"
                                v-model="checkbox"
                                small
                                label="When submitting this form, I am agreeing that the given information about my business & the planned events will be displayed on the action map, shared for the campaign and by its partners. ardkon is applying their privacy policy "
                            ></v-checkbox>
                        </v-card-subtitle>
                        <v-card-subtitle
                            class="caption"
                        >ardkon.com is not the initiator of the individual events within the campaign and cannot be held responsible for them nor the content provided by the participants.</v-card-subtitle>
                        <br />
                        <span class="red--text">{{ message }}</span>
                        <br />
                    </v-col>
                </v-row>

                <v-progress-linear v-if="loadingBar" color="info accent-4" indeterminate rounded height="6"></v-progress-linear>
                <br />
                <v-row>
                    <v-col md="12">
                        <v-card-title>
                            Coming soon: 
                        </v-card-title>
                        <v-card-text>
                           
Your event will be pinned on your location on the LI BEIRUT action map!
<br>

Thank you for participating with a small act. You will receive an email with the further details shortly. 
<br>

ardkon.com & partners
                        </v-card-text>
                    </v-col>
                </v-row>
                <v-card-text>
                    <span class="error--text">{{message_error}}</span><br>
                    <v-btn class="mr-4"  color="primary" :disabled="checkbox ===  false"  @click="submit">submit</v-btn>
                </v-card-text>
                
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import LiBeirutImg from '@/assets/LiBeirut.jpeg'
import LiBeirutImg1 from '@/assets/LiBeirut-D.jpeg'
import LiBeirutImg2 from '@/assets/poster_action_map_.jpg'
import LiBeirutImg1122 from '@/assets/LiBeirut1122.jpeg'

import { required, minLength, between } from 'vuelidate/lib/validators'

import store from '@/store'
export default {
    name: 'venue-donation-item',
    data() {
        return {
            ideas: [
                'I am simply dedicating the day in thoughts of the Beirut blast and want to make my customers aware ', 'I am planning a small concert', 'The kitchen / volunteers are preparing Lebanese dishes', 'We will screen a movie', 'There will be a lecture/ panel discussions', 'other'
            ], donations_type: [
                'We will dedicate a percentage of the revenue of that day to the projects ', 'The menu will contain a special donation item (Lebanese coffee or a certain dish) ', 'We’ll have an extra donation tip jar / option on the bill', 'other'
            ],
            types: ['item donation', 'revenue percentage donation', 'tip jar'],
            initiative_types: ['reconstruction', 'medical support', 'psychological support', 'Food support', 'other'],
            img: LiBeirutImg,
            img1: LiBeirutImg1,
            img2: LiBeirutImg2,
            img123: LiBeirutImg1122,
            checkbox: false,
            form_1: {
                logo: {},
                logo_url: '',
                poster:{},
                poster_url:''
            },
            message_error:'',
            form: {
                venue_name: '',
                venue_street: '',
                venue_number: '',
                venue_country: '',
                short_description:'',
                logo_url:'',
                poster_url:'',
                google_maps_link: '',
                program_ideas: '',
                program_idea: '',
                donation_type: '',
                donations_type: '',
                initiative_types:'',
                initiative_type:'',
                connections: '',
                name:'',
                email_address:'',
                address: '',
                social_media_link:''


            }

        }

    },
    validations: {
        venue_name: {
            required,
            minLength: minLength(4)
        },
        age: {
            between: between(20, 30)
        }
    },
    computed: {
        message(){
            return store.state.forms.message_form
        },
        loadingBar(){
            return store.state.forms.loading_bar
        },
        url_message() {
            return store.state.forms.message
        },poster_message() {
            return store.state.forms.message_poster
        },
        venue_img_url() {
            return store.state.forms.img_uploaded
        },
        venue_poster_url() {
            return store.state.forms.poster_uploaded
        }

    },
    methods: {

        async check(){
            store.dispatch('forms/loadingTrue')
            this.form.logo_url = this.venue_img_url
            this.form.poster_url = this.venue_poster_url
            if(this.form.program_idea === ''){
                this.form.program_idea = this.form.program_ideas
            }
            if(this.form.donation_type === ''){
                this.form.donation_type = this.form.donations_type
            }
            if(this.form.initiative_type === ''){
                this.form.initiative_type = this.form.initiative_types
            }

            return true
        },
       async submit(){

            this.check().then((result) =>{
                console.log(result)
                if(this.form.venue_name != '' & this.form.venue_street != '' &  this.form.venue_number != '' &   this.form.venue_country != '' & this.form.short_description != '' & this.form.logo_url != '' & this.form.poster_url != '' & this.form.google_maps_link != '' &        this.form.program_idea != '' & this.form.donation_type != '' & this.form.initiative_type !='' & this.form.connections != '' & this.form.name != '' & this.form.email_address != '' &      this.form.address != '' &   this.form.social_media_link != ''){
                store.dispatch('forms/liBeirutForm', this.form)
                this.message_error = ""


            } else {
                store.dispatch('forms/loadingFalse')

                if(this.form.venue_name === ''){
                    this.message_error = "add venue name"

                } 
                if(this.form.venue_street === ''){
                    this.message_error = "add venue street"

                } if(this.form.venue_number === ''){
                    this.message_error = "add venue number"

                }
                if(this.form.venue_country === ''){
                    this.message_error = "add venue country"

                }if(this.form.short_description === ''){
                    this.message_error = "add venue short description"

                }if(this.form.logo_url === ''){
                    this.message_error = "add venue logo"

                }if(this.form.poster_url === ''){
                    this.message_error = "add venue poster or place picture"

                }if(this.form.google_maps_link === ''){
                    this.message_error = "add venue poster or google maps link"

                }if(this.form.program_idea === ''){
                    this.message_error = "add venue program idea"

                }if(this.form.donation_type === ''){
                    this.message_error = "add donation type"

                }if(this.form.initiative_type === ''){
                    this.message_error = "add initiative type"

                }if(this.form.connections === ''){
                    this.message_error = "add connections"

                }if(this.form.name === ''){
                    this.message_error = "add name"

                }if(this.form.email_address === ''){
                    this.message_error = "add email address"

                }if(this.form.address === ''){
                    this.message_error = "add address"

                }if(this.form.social_media_link === ''){
                    this.message_error = "add social media link"

                }

            }
            })
           
            console.log(this.form)

           


        },
        imageToBase64() {
            var reader = new FileReader()
            reader.readAsDataURL(this.form_1.logo)
            reader.onload = () => {


                this.form_1.logo_url = reader.result.split(',')[1]
                store.dispatch('forms/uploadImage', { image: this.form_1.logo_url })

            }
            reader.onerror = function(error) {
                console.log('Error: ', error)

            }
        }, imageToBase641() {
            var reader = new FileReader()
            reader.readAsDataURL(this.form_1.poster)
            reader.onload = () => {


                this.form_1.poster_url = reader.result.split(',')[1]
                store.dispatch('forms/uploadImage1', { image: this.form_1.poster_url })

            }
            reader.onerror = function(error) {
                console.log('Error: ', error)

            }
        }
    }
}
</script>