<template>
<div>
  <v-app-bar app color="primary" dark>
            <h3>LI BEIRUT action day</h3>
        </v-app-bar>
        <li-beirut-form />

</div>
</template>

<script>
import LiBeirutForm from '@/components/LiBeirut/LiBeirutForm.vue'

  export default {
    name: 'LiBeirut',

    components: {
      LiBeirutForm,
    },
    mounted(){
      document.title = 'Li Beirut'
    }
  }
</script>
