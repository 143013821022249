<template>
<div>
    <v-app-bar dense   color="rgba(32, 63, 94, .69)">
<h3 class="white--text">
    acts</h3>

    <v-spacer></v-spacer>
    
    <v-tooltip
          v-model="show"
          color="primary"
          top
          max-width="210"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              href="https://ardkon.s3.eu-central-1.amazonaws.com/helo/action portal LI BEIRUT Sep 21.pdf"
              target="_Blank"


            >
            <v-icon small 
      color="white darken-2" >
                mdi-alert-circle-outline
            
          </v-icon>
            </v-btn>
          </template>
          <span></span>
          <h4>
              acts
          </h4>
          <p>
            the global community is with Beirut in small acts. explore events nearby and register your act here!<br><span class="text-center font-weight-bold">click to know more!!!</span>   </p>
        </v-tooltip>

<v-spacer></v-spacer>
<v-spacer></v-spacer>
<v-spacer></v-spacer>
<v-spacer></v-spacer>
<v-spacer></v-spacer>
<v-spacer></v-spacer>
<v-spacer></v-spacer>
<v-spacer></v-spacer>
<v-btn x-small class="h2 white--text text-lowercase" @click="venue_dialog = true" outlined color="white" >register</v-btn>
</v-app-bar>
<v-dialog max-width="900" v-model="venue_dialog">
   
        <LiBeirutForm_1></LiBeirutForm_1>

   

</v-dialog>
<v-row class="venues" no-gutters>
    <v-col v-for="venue in venues" :key="venue.act_id" cols="12">
        <venue :venue="venue.properties"></venue>
    </v-col>
</v-row>
</div>
</template>
<script>
import Venue from './Venue.vue'
import LiBeirutForm_1 from '../LiBeirut/LiBeirutForm_1.vue'
import store from '@/store'
export default {
    name: 'venues',
    components:{
        Venue,
        LiBeirutForm_1
    },
    computed:{
        venues(){
            return store.state.venues.acts
        }
    },
    data(){
        return {
            venue_dialog:false,
            //venues:[],
            venues_s:[
                {
                    venue_id: '155632vwd-dw4w544dw-dw44d7w',
                    venue_dialogue: false,
                    venue_name: 'event Name',
                    venue_small_description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                    venue_description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?',
                    venue_linked_in_url: '',
                    venue_website_url: '',
                    venue_instagram_url: '',
                    venue_facebook_url: '',
                    venue_img_src:'https://ard-platform.s3.eu-central-1.amazonaws.com/profile-members/hero-to-back-1614070289684.png'
                },  {
                    venue_id: '155632vwd-dw4w544dw-dw44d7w',
                    venue_dialogue: false,

                    venue_name: 'event Name',
                    venue_small_description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                    venue_description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?',
                    venue_linked_in_url: '',
                    venue_website_url: '',
                    venue_instagram_url: '',
                    venue_facebook_url: '',
                    venue_img_src:'https://ard-platform.s3.eu-central-1.amazonaws.com/profile-members/hero-to-back-1614070289684.png'
                },  {
                    venue_id: '155632vwd-dw4w544dw-dw44d7w',
                    venue_dialogue: false,

                    venue_name: 'event Name',
                    venue_small_description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                    venue_description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?',
                    venue_linked_in_url: '',
                    venue_website_url: '',
                    venue_instagram_url: '',
                    venue_facebook_url: '',
                    venue_img_src:'https://ard-platform.s3.eu-central-1.amazonaws.com/profile-members/hero-to-back-1614070289684.png'
                },  {
                    venue_id: '155632vwd-dw4w544dw-dw44d7w',
                    venue_dialogue: false,

                    venue_name: 'event Name',
                    venue_small_description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                    venue_description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?',
                    venue_linked_in_url: '',
                    venue_website_url: '',
                    venue_instagram_url: '',
                    venue_facebook_url: '',
                    venue_img_src:'https://ard-platform.s3.eu-central-1.amazonaws.com/profile-members/hero-to-back-1614070289684.png'
                },  {
                    venue_id: '155632vwd-dw4w544dw-dw44d7w',
                    venue_dialogue: false,

                    venue_name: 'venue Name',
                    venue_small_description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                    venue_description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?',
                    venue_linked_in_url: '',
                    venue_website_url: '',
                    venue_instagram_url: '',
                    venue_facebook_url: '',
                    venue_img_src:'https://ard-platform.s3.eu-central-1.amazonaws.com/profile-members/hero-to-back-1614070289684.png'
                },  {
                    venue_id: '155632vwd-dw4w544dw-dw44d7w',
                    venue_dialogue: false,

                    venue_name: 'venue Name',
                    venue_small_description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                    venue_description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?',
                    venue_linked_in_url: '',
                    venue_website_url: '',
                    venue_instagram_url: '',
                    venue_facebook_url: '',
                    venue_img_src:'https://ard-platform.s3.eu-central-1.amazonaws.com/profile-members/hero-to-back-1614070289684.png'
                },

            ]
        }
    }
}
</script>
<style>
.venues{
    max-height: 38vh;
    overflow-y: scroll;

   
}
</style>