<template>
<div>
    <v-row>
        <v-col>
            <v-card>
                <v-card-title>
                    fund allocation  
                    frief
                </v-card-title>
            </v-card>
        </v-col>
    </v-row>
</div>
</template>
<script>
export default {
    name: 'fund-allocation',
    data(){
        return {
            
        }
    }
}
</script>