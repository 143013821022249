<template>
<div>
   
   <v-card-text>
    <v-row class="partners" no-gutters>
  
  <v-col v-for="partner in partners" :key="partner.partner_id" cols="12" class="my-auto" md="4">
      <partner :partner="partner"></partner>
  </v-col>
</v-row>
   </v-card-text>

</div>
</template>
<script>
import Partner from './Partner.vue'
import store from '@/store'
export default {
    name: 'partners',
    components:{
        Partner,
        
    },
    data(){
        return {
            
        }
    },
    computed: {
        partners(){
            return store.state.partners.active_campaign.partners
        }
    }
}
</script>
<style>

</style>