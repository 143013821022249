import axios from 'axios'

const actions = {
    call({commit},form){
        commit('CALL', form)
    },
    async setActiveVenue({dispatch, commit}, form){

        dispatch('deleteRenderedVenues').then(r =>
            {
                commit('SET_ACTIVE_VENUE', form)
                console.log(r)
            })
       

    },
    async deleteRenderedVenues({commit}){
        commit('DELETE_RENDERED_VENUES')
    },
    async addRenderedVenues ({ dispatch, commit }, form) {

      
        dispatch('deleteRenderedVenues').then(r =>
            {
                commit('SET_RENDERED_VENUES', form)
                console.log(r)
            })
                          
    
    
    
    
                      
        }, async getInitiatives ({  commit }, form) {

        return   axios
                        .post('https://techard.net/GetInitiatives?hello=ss', form)
                        .then(response => {
    
                            console.log(response)
                           commit('SET_INITIATIVES', response.data.data.action_initiatives)
    
    
    
    
                        }).catch(error => {
                              console.log(error)
                          });
        },async getActs ({  commit }, form) {

        return   axios
                        .post('https://apiss.ardkon.com/ActGetDetails?basics=campaign2', form)
                        .then(response => {
    
                            console.log(response)
                           commit('SET_ACTS', response.data.data.acts_details)
    
    
    
    
                        }).catch(error => {
                              console.log(error)
                          });
        },
}
const mutations = {
    CALL(state,data){
        state.call.coordinates = data
        state.call.boolean = true
    },
    SET_ACTS(state,data ){
        for(let i = 0; i < data.length; i++){

            state.acts.push(data[i]);


          }

    },  SET_PARTNERS(state,data ){
        for(let i = 0; i < data.length; i++){

            state.active_campaign.partners.push(data[i]);


          }

    }, SET_RENDERED_VENUES(state,data ){
        

        for(let i = 0; i < data.length; i++){

            state.active_venues.push(data[i].properties);


          }

    }, SET_ACTIVE_VENUE(state,data ){
        

        data.properties.act_dialog = true

            state.active_venue = data.properties;


          

    }, DELETE_RENDERED_VENUES(state ){
        

       

            state.active_venues = []



    }, SET_INITIATIVES(state,data ){

        for(let i = 0; i < data.length; i++){

            data[i].initiative_dialogue = false;

            state.active_campaign.initiatives.push(data[i]);


          }

    }
}

const venues = {
    state:{
        call:{
            boolean: false
        },
        map:{},
        active_venues:[],
        active_venue: {},
        acts:[]
    },
    namespaced: true,
    actions,
    mutations
}

export default venues