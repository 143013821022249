<template>
    <div  >
 
        <v-card  elevation="0" color="transparent" >
            <v-carousel        :show-arrows="false"
cycle  v-model="model" 
delimiter-icon="mdi-minus"
      height="44vh"
>
    <v-carousel-item v-for="item in items" :key="item"
     
    >
      <v-img :src="item" contain  height="44vh"   aspect-ratio="1"
  ></v-img>
    </v-carousel-item> 
  </v-carousel>
          
        </v-card>
        <v-dialog v-model="open">
            <v-card>
                <v-card-text>
                    <v-row>
                        <v-col md="6">
                            <v-img :src="img2" fluid class="my-5 py-5"></v-img>
                        </v-col>
                        <v-col class="my-auto" md="6">
                            <v-card-title
                                class="font-weight-bold primary--text"
                            >This August 4, Beirut is everywhere.</v-card-title>
                            <v-card-text>
                                <span class="caption">
                                    A year after the devastating explosion in the Beirut port, volunteers and initiatives have been continuously rebuilding houses, delivering food and giving social support.
                                    <br />
                                </span>
                            </v-card-text>

                            <v-card-text>
                                August 4, 2020 in Beirut was a day that took away many lives and changed others forever. This August 4, 2021 restaurants, bars & cafés worldwide will open their doors like every day but with a twist. Businesses will dedicate the day to the memory of victims and affected by the Beirut blast.
                                <br />ardkon is launching an action map to pin all the events happening on August 4 2021.
                                <br />Whether it’s food, concerts, lectures, movies – we would invite you to support this action day with your business or event!
                                <br />
                                <br />Donations out of the return of this action day will support the continuous work of the initiatives on the ground – that is highly appreciated & and still needed urgently .
                                <br />
                                <br />In solidarity we will listen to music and stories and continue to talk about Beirut. The world to stand with Beirut in small acts.
                                <br />
                                <br />
                            </v-card-text>
                        </v-col>
                       
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import LiBeirutImg2 from '@/assets/poster_action_map_.jpg'

export default {
    name: 'InfoComponent',
    data() {
        return {
            open: false,
            items:[
                'https://ardkon.s3.eu-central-1.amazonaws.com/profile-members/hero-to-back-1634218795057.png',
                'https://ardkon.s3.eu-central-1.amazonaws.com/profile-members/hero-to-back-1634218841345.png',
                'https://ardkon.s3.eu-central-1.amazonaws.com/profile-members/hero-to-back-1634218893942.png',
                'https://ardkon.s3.eu-central-1.amazonaws.com/profile-members/hero-to-back-1634218974476.png',
                'https://ardkon.s3.eu-central-1.amazonaws.com/profile-members/hero-to-back-1634219009425.png',
                'https://ardkon.s3.eu-central-1.amazonaws.com/profile-members/hero-to-back-1634219046762.png',
                'https://ardkon.s3.eu-central-1.amazonaws.com/profile-members/hero-to-back-1634219083430.png',
                'https://ardkon.s3.eu-central-1.amazonaws.com/profile-members/hero-to-back-1634219121283.png',
                'https://ardkon.s3.eu-central-1.amazonaws.com/profile-members/hero-to-back-1634219157291.png',
                'https://ardkon.s3.eu-central-1.amazonaws.com/profile-members/hero-to-back-1634219197333.png',
                'https://ardkon.s3.eu-central-1.amazonaws.com/profile-members/hero-to-back-1634219236615.png'

               ],
            img2: LiBeirutImg2,
         
        }
    }
}
</script>
<style>
.info-card {
    border-radius: 0px !important;
    height:44vh !important;
    max-height:41vh;
}
.img-slide { 
    width:100%; 
    height:100%; 
    } 
</style>