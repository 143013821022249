import axios from 'axios'


const actions = {
    async getStreams({  commit }, form) {

        return   axios
                        .post('https://apiss.ardkon.com/BrGetDetails?basics=campaign2', form)
                        .then(response => {
    
                            console.log(response)
                           commit('SET_STREAMS', response.data.data.acts_details)
                           commit('SET_STREAM', response.data.data.acts_details[0].properties)
    
    
    
    
                        }).catch(error => {
                              console.log(error)
                          });
        },
   
    setStream({commit}, data){
        commit('SET_STREAM',data)
    }
}

const mutations = {
    STREAMS(state, data){
        state.online_streams.push(data)
    },
    SET_STREAM(state,data){
        state.active_stream = data
    },
    SET_STREAMS(state,data ){
        for(let i = 0; i < data.length; i++){

            state.streams.push(data[i].properties);


          }

    },
}

const streaming ={
    namespaced: true,
    state:{
        online_streams: [],
        active_stream:{
            stream_type: 'video',
            video_id: 'ardkon',
            channel_name: 'ardkon'
        },
        streams:[]
    },
    mutations,
    actions
}

export default streaming;