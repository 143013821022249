<template>
    <div>
        <v-card @click="venue.act_dialog = true" color="255,255,255,0.36)" class="card-venue ma-1">
            <v-row no-gutters>
                <v-col cols="4" class="image-card">
                    <v-img height="100%" width="100%" :src="venue.act_logo_url"></v-img>
                </v-col>
                <v-col class="my-auto" md="8">
                    <v-card-text class="text-caption font-weight-bold">{{ venue.act_name }}</v-card-text>
                </v-col>
                <v-col class="my-auto" cols="3"></v-col>
            </v-row>
        </v-card>
        <v-dialog max-width="420" v-model="venue.act_dialog">
            <v-card class="ma-1">
                <v-card-text>
                    <v-row>
                        <v-col cols="4" class="my-auto">
                            <v-img height="100%" width="100%" :src="venue.act_poster_url"></v-img>
                        </v-col>
                        <v-col class="my-auto" md="8">
                            <v-card-text class="text-caption font-weight-bold">{{ venue.act_name }}</v-card-text>
                            <v-card-text>{{ venue.act_description }}</v-card-text>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12"></v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog max-width="420" v-model="venus_d.act_dialog">
            <v-card class="ma-1">
                <v-card-text>
                    <v-row>
                        <v-col cols="4" class="my-auto">
                            <v-img height="100%" width="100%" :src="venus_d.act_poster_url"></v-img>
                        </v-col>
                        <v-col class="my-auto" md="8">
                            <v-card-text class="text-caption font-weight-bold">{{ venus_d.act_name }}</v-card-text>
                            <v-card-text
                                class="text-caption font-weight-bold"
                            >{{ venus_d.act_description }}</v-card-text>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12"></v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import store from '@/store'
export default {
    name: 'venue',
    props: {
        venue: Object
    },
    methods: {
        goToMap() {
            store.dispatch('venues/call', [2,2])

         
        }
    },
    computed: {
        venus_d() {
            return store.state.venues.active_venue
        }, map() {
            return store.state.venues.map
        }
    }
}
</script>
<style>
.card-venue {
    max-height: 4.2rem;
    overflow-y: hidden;
    background-color: rgba(255, 255, 255, 0.63) !important;
    border-radius: 0px !important;
    transition: transform 0.2s; /* Animation */
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12),
        0 3px 10px 0 rgba(0, 0, 0, 0.12);
    background-color: rgba(226, 229, 232, 0.63);
    overflow: hidden;
}
.card-venue:hover {
    transform: scale(0.9);
}
.image-card {
    height: 4.2rem;
}

.image-card:hover {
    transform: scale(1.2);
}
</style>