<template>

    <div>
       
        
        <v-card
        border-variant="primary"
        
                
        id="topic"
       class=" mx-4   p-0  d-flex topic card-menu-white " 
        >
        <v-row     class="h-100">
           
              <v-col sm="12"   class=" shaddow-text text-center " style="position:relative;vertical-align: middle;top:25%" >
               
        

              <v-img fluid @click="goTo" style="cursor: pointer;"  src="https://ard-platform.s3.eu-central-1.amazonaws.com/profile-members/hero-to-back-1619585229718.png" ></v-img>
              
                                </v-col>

           </v-row>
        </v-card>
 
  

</div>
  </template>
  <script>
  export default {
      name: 'linked-in',
      components: {
  
      },
      props: {
        topic: Object,
      },
      data() {
        return {
            
        }
        },
        methods: {
            goTo(){
                window.location.href = "https://api.linkedin.com/oauth/v2/authorization?redirect_uri=http://localhost:8081/auth&response_type=code&state=bitchplease&grant_type=authorization_code&scope=r_liteprofile+r_emailaddress+w_member_social&client_id=78dmbk81tskav0"
                console.log('hii')
            },
        showModal() {
             this.$root.$emit('bv::show::modal', this.topic.action_user.action_user_id, '#btnShow')
           },
        }
  
    }
  </script>
  <style >
  .activity-card{
    max-height: 21vh;
    max-height: 21vh;
  }
  .topic-img{
   max-height: 12vh;
    max-width: 12vw;
  }
  
  .text-bold{
    font-weight:600;
  
  }
  .topic-text{
    max-height: 2.1rem; overflow: hidden;
  }
  .topic{
  border:0
  }
  .topic .card-img-left {
               width: 100%;
               height: 100%;
               max-height: 7.2rem;
               min-height: 7.2rem;
               max-width: 6rem;
               min-width: 6rem;
               object-fit: cover;
               border-top-left-radius: 3px;
               border-bottom-left-radius: 3px;
           }
  .card-menu-dark{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color:rgba(0, 0, 0, 0.63);
    max-width: 20 vw;
    border-radius: 18px!important;
  
  } .card-menu-white{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color:rgba(255, 255, 255, 0.63);
    max-width: 20 vw;
    border-radius: 18px!important;
  
  }
.voted{
    border-style: solid;
    border: 9px!important;
    border-color: #132F3E;
}
  .card-menu-variant{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-image:linear-gradient(to right, #132F3E , #1A4B35);
    max-width: 20 vw;
    border-radius: 18px!important;
  }

  
  </style>
  