import axios from 'axios'

const actions = {
    async getPartners ({  commit }, form) {

        return   axios
                        .post('https://apiss.ardkon.com/GetPartners?hello=ss', form)
                        .then(response => {
    
                            console.log(response)
                           commit('SET_PARTNERS', response.data.data.action_partners)
    
    
    
    
                        }).catch(error => {
                              console.log(error)
                          });
        }, async getInitiatives ({  commit }, form) {

        return   axios
                        .post('https://apiss.ardkon.com/GetInitiatives?hello=ss', form)
                        .then(response => {
    
                            console.log(response)
                           commit('SET_INITIATIVES', response.data.data.action_initiatives)
    
    
    
    
                        }).catch(error => {
                              console.log(error)
                          });
        },
}
const mutations = {
    SET_PARTNERS(state,data ){
        for(let i = 0; i < data.length; i++){

            state.active_campaign.partners.push(data[i]);


          }

    }, SET_INITIATIVES(state,data ){

        for(let i = 0; i < data.length; i++){

            data[i].initiative_dialogue = false;

            state.active_campaign.initiatives.push(data[i]);


          }

    }
}

const partners = {
    state:{
        active_campaign:{
            partners:[],
            initiatives: []
        }
    },
    namespaced: true,
    actions,
    mutations
}

export default partners