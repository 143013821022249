<template>
  <div>
    {{lat}}
    <MglMap
      class="first-section"
      :accessToken="accessToken"
      :mapStyle="mapStyle"
      :mapboxGl="mapbox"
      width="100%"
      height="100%"
      :center="[35.8547, 33.8]"
      :zoom="4"
      @load="loaded"
    ></MglMap>
  </div>
</template>
  
  <script>

import mapboxgl from "mapbox-gl";
import store from "@/store"
import { MglMap } from "vue-mapbox";
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

export default {
  name: 'map-component',

  components: {

    MglMap,
  },
  data() {
    return {
      coordinates_point: [33.3, 33.2],
      mapbox: mapboxgl,
      accessToken: 'pk.eyJ1IjoicGxhbmV0MzY5IiwiYSI6ImNrcmR3ZXZ6ZDBpbHgyb29laHR2d3pjcHUifQ.riIQ-PY811vasvyycMyn3g', // your access token. Needed if you using Mapbox maps
      mapStyle: 'mapbox://styles/planet369/ckrdwj57j2w0m18mjfzizwmm3',
      airports: [],


      geoJsonSource: {
        type: 'geojson',
        data: {
          id: "thisIsMySource",
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [102.0, 0.5]
              },
              properties: {
                id: "value0",
                event_type: 'broadcast'

              }
            },
            {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [33.6, 33.8]
              },
              properties: {
                id: "value0",
                event_type: 'fundraiser'
              }
            }
          ]
        },
      },
      lat:0,
      long:0,
      geoJsonLayer: {
        type: "circle",
        paint: {
          "circle-color": [
            'match',
            ['get', 'event_type'],
            "fundraiser", "red",
            "broadcast", "#E25000",

            'grey'
          ],
          'circle-radius': {
            'base': 2,
            'stops': [
              [4, 12],
              [12, 60]
            ]
          },
        }
      }


    }
  },
  created() {

  },
  mounted() {
    document.title = 'Action Li Beirut'


  },
  methods: {


    loadMap() {

    },
    showLayer(map, mapboxEvent, layerId, component) {
      console.log(map)
      console.log(mapboxEvent)
      console.log(layerId)
      console.log(component)
      var e = map.mapboxEvent
      var id = e.features[0].properties.id;
      console.log(e)

      new mapboxgl.Popup()
        .setLngLat(e.lngLat)
        .setHTML(id)
        .addTo(map)
    },
    clicked(map) {


      console.log(map)
    },

    getUniqueFeatures(array, comparatorProperty) {
      var existingFeatureKeys = {};
      // Because features come from tiled vector data, feature geometries may be split
      // or duplicated across tile boundaries and, as a result, features may appear
      // multiple times in query results.
      var uniqueFeatures = array.filter(function (el) {
        if (existingFeatureKeys[el.properties[comparatorProperty]]) {
          return false;
        } else {
          existingFeatureKeys[el.properties[comparatorProperty]] = true;
          return true;
        }
      });

      return uniqueFeatures;
    },
    loaded(map) {

      console.log(map)
      map.map.addControl(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl,
          marker: false, // Do not use the default marker style
        }))


        var marker = new mapboxgl.Marker({
                            draggable: true
                            })
                            .setLngLat([35.52, 33.88])
                            .addTo(map.map);
                             
                           
                          function onDragEnd() {
                           
                            var newpinlngLat = marker.getLngLat();
                            console.log("now")
                          
                            console.log(newpinlngLat)
                        }
                         
                             
                            marker.on('dragend', onDragEnd())
                            

                      }                               


    
  
  },
  computed: {
    features() {
      return store.state.venues.acts
    },
    popup() {
      return new mapboxgl.Popup({
        closeButton: false
      });
    },
    filterEl() {
      return this.$refs.featurefilter
    },
    normalize(string) {
      return string.trim().toLowerCase();
    },

  }

}
</script>
  <style>
#map {
  position: absolute;
  left: 25%;
  top: 0;
  bottom: 0;
  width: 75%;
}
.map-overlay {
  position: absolute;
  width: 48.5%;
  height: 43.5vh;
  top: 4.2rem;
  bottom: 0;
  left: 1.2rem;
  font: 12px/20px "Helvetica Neue", Arial, Helvetica, sans-serif;
  background-color: #222222;
  max-height: 100%;
  overflow: hidden;
}

.map-overlay fieldset {
  display: none;
  background: #ddd;
  border: none;
  padding: 10px;
  margin: 0;
}

.map-overlay input {
  display: block;
  border: none;
  width: 100%;
  border-radius: 3px;
  padding: 10px;
  margin: 0;
  box-sizing: border-box;
}

.map-overlay .listing {
  overflow: auto;
  max-height: 100%;
}

.map-overlay .listing > * {
  display: block;
  padding: 5px 10px;
  margin: 0;
}

.map-overlay .listing a {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: #404;
  text-decoration: none;
}

.map-overlay .listing a:last-child {
  border: none;
}

.map-overlay .listing a:hover {
  background: #f0f0f0;
}
</style>