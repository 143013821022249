<template>
    <div>
      
      
        <v-row class="my-2" v-if="is_signed != true">
            <v-col cols="12">
                <InitiativeSignIn></InitiativeSignIn>
            </v-col>
        </v-row>

       
    </div>
</template>
<script>

import InitiativeSignIn from '../components/Initiatives/Dashboard/InitiativeSignIn'
export default {
    components: {
   
        InitiativeSignIn,
       
    },
    name: 'initiative-page'
}
</script>