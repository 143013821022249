<template>
  <v-app>

    
    <v-main class="Main-Frame">
      <router-view/>
      
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
  created() {
    console.log(this.$isMobile())
    if(this.$isMobile()){
      window.screen.orientation.lock('landscape');
      console.log("hi")
    }
  }
};
</script>
<style>
.Main-Frame {
  background: url("/Map.svg");
  background-repeat: no-repeat;
  background-position: top, left;
  background-size: cover;
  height: 100vh;
  background-attachment: fixed;

  background-color: #f9f9f9;
  overflow-x: hidden;
  overflow-y: scroll;
}

@media only screen and (max-width: 981px) {
  .Main-Frame {

    height: auto;
   

  }
}


.card-transparent{
    background-color:rgba(255,255, 255,0.63)!important;
   
  }

  .theme--dark .v-application{
    background: #203F5E!important;
  }


::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #40454c;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.container {
  justify-content: space-around;    
}
</style>

