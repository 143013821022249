<template>
  <div>
    <MglMap
      class="first-section"
      :accessToken="accessToken"
      :mapStyle="mapStyle"
      :mapboxGl="mapbox"
      width="100%"
      height="100%"
      :center="[35.8547, 15.8]"
      :zoom="0"
      @load="loaded"
    ></MglMap>
  </div>
</template>
  
  <script>

import mapboxgl from "mapbox-gl";
import store from "@/store"
import { MglMap } from "vue-mapbox";

export default {
  name: 'map-component',

  components: {

    MglMap,
  },
  data() {
    return {
      coordinates_point: [33.3, 33.2],
      mapbox: mapboxgl,
      accessToken: 'pk.eyJ1IjoicGxhbmV0MzY5IiwiYSI6ImNrcmR3ZXZ6ZDBpbHgyb29laHR2d3pjcHUifQ.riIQ-PY811vasvyycMyn3g', // your access token. Needed if you using Mapbox maps
      mapStyle: 'mapbox://styles/planet369/ckrdwj57j2w0m18mjfzizwmm3',
      airports: [],


      geoJsonSource: {
        type: 'geojson',
        data: {
          id: "thisIsMySource",
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [102.0, 0.5]
              },
              properties: {
                id: "value0",
                event_type: 'broadcast'

              }
            },
            {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [33.6, 33.8]
              },
              properties: {
                id: "value0",
                event_type: 'fundraiser'
              }
            }
          ]
        },
      },
      geoJsonLayer: {
        type: "circle",
        paint: {
          "circle-color": [
            'match',
            ['get', 'event_type'],
            "fundraiser", "red",
            "broadcast", "#E25000",

            'grey'
          ],
          'circle-radius': {
            'base': 2,
            'stops': [
              [4, 12],
              [12, 60]
            ]
          },
        }
      }


    }
  },
  created() {

  },
  mounted() {
    document.title = 'Action Li Beirut'


  },
  methods: {


    loadMap() {

    },
    showLayer(map, mapboxEvent, layerId, component) {
      console.log(map)
      console.log(mapboxEvent)
      console.log(layerId)
      console.log(component)
      var e = map.mapboxEvent
      var id = e.features[0].properties.id;
      console.log(e)

      new mapboxgl.Popup()
        .setLngLat(e.lngLat)
        .setHTML(id)
        .addTo(map)
    },
    clicked(map) {


      console.log(map)
    },

    getUniqueFeatures(array, comparatorProperty) {
      var existingFeatureKeys = {};
      // Because features come from tiled vector data, feature geometries may be split
      // or duplicated across tile boundaries and, as a result, features may appear
      // multiple times in query results.
      var uniqueFeatures = array.filter(function (el) {
        if (existingFeatureKeys[el.properties[comparatorProperty]]) {
          return false;
        } else {
          existingFeatureKeys[el.properties[comparatorProperty]] = true;
          return true;
        }
      });

      return uniqueFeatures;
    },
    loaded(map) {

      console.log(map)


      map.map.addSource('points', {
        'type': 'geojson',
        'data': {
          'id': "thisIsMySource",
          'type': "FeatureCollection",
          'features': this.features
        },
      });


      console.log(map)
      map.map.addLayer({
        'id': 'queries',
        'type': 'circle',
        'source': 'points',
        'paint': {
          'circle-color': "white",
          'circle-radius': 6,
        }
      });

      var size = 150;



      //-------- LAYERS - blinking -----------//    
      var pulsingDot_ALL = {

        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),

        // get rendering context for the map canvas when layer is added to the map
        onAdd: function () {
          var canvas = document.createElement('canvas');
          canvas.width = this.width;
          canvas.height = this.height;
          this.context = canvas.getContext('2d');

        },

        // called once before every frame where the icon will be used
        render: function () {
          var duration = 1000;
          var t = (performance.now() % duration) / duration;

          var radius = (size / 2) * 0.3;
          var outerRadius = (size / 2) * 0.7 * t + radius;
          var context = this.context;

          // draw outer circle
          context.clearRect(0, 0, this.width, this.height);
          context.beginPath();
          context.arc(
            this.width / 2,
            this.height / 2,
            outerRadius,
            0,
            Math.PI * 2
          );
          context.fillStyle = 'rgba(255, 255, 255,' + (1 - t) + ')';
          context.fill();

          // draw inner circle
          context.beginPath();
          context.arc(
            this.width / 2,
            this.height / 2,
            radius,
            0,
            Math.PI * 2
          );
          context.fillStyle = 'rgba(0, 0, 0, 1)';
          context.strokeStyle = 'white';
          context.lineWidth = 2 + 4 * (1 - t);
          context.fill();
          context.stroke();

          // update this image's data with data from the canvas
          this.data = context.getImageData(
            0,
            0,
            this.width,
            this.height
          ).data;

          // continuously repaint the map, resulting in the smooth animation of the dot
          map.map.triggerRepaint();

          // return `true` to let the map know that the image was updated
          return true;
        }
      };


      map.map.addImage('pulsing-dot_ALL', pulsingDot_ALL, { pixelRatio: 3 });

      map.map.addLayer({
        'id': 'ALL_pulsing',
        'type': 'symbol',
        'source': 'points',
        'layout': {
          'visibility': 'visible',
          'icon-image': 'pulsing-dot_ALL'
        },
        'filter': ['==', 'act_type', 'acts']
      });

      map.map.on('click', 'queries', function (e) {

        var feature = e.features[0];
        console.log(feature)
        map.map.flyTo({
          center: e.features[0].geometry.coordinates,
          offset: [0, 1],
        });

        store.dispatch('venues/setActiveVenue', feature)


      })


      map.map.on('moveend', function () {
        var features = map.map.queryRenderedFeatures({ layers: ['queries'] });
        console.log(features)

        store.dispatch('venues/addRenderedVenues', features)
      });




    },
   
    renderListings(map, features) {
      var empty = document.createElement('p');
      this.listingEl.innerHTML = '';

      if (features.length) {
        features.forEach(function (feature) {
          var prop = feature.properties;
          var item = document.createElement('a');
          item.href = prop.wikipedia;
          item.target = '_blank';
          item.textContent = prop.name + ' (' + prop.abbrev + ')';
          item.addEventListener('mouseover', function () {
            // Highlight corresponding feature on the map
            this.popup
              .setLngLat(feature.geometry.coordinates)
              .setText(
                feature.properties.name +
                ' (' +
                feature.properties.abbrev +
                ')'
              )
              .addTo(map.map);
          });
          this.listingEl.appendChild(item);
        });

        // Show the filter input
        this.filterEl.parentNode.style.display = 'block';

      } else if (features.length === 0 && this.filterEl.value !== '') {
        empty.textContent = 'No results found';
        this.listingEl.appendChild(empty);
      } else {
        empty.textContent = 'Drag the map to populate results';
        this.listingEl.appendChild(empty);

        // Hide the filter input
        this.filterEl.parentNode.style.display = 'none';

        // remove features filter
        map.setFilter('airport', ['has', 'abbrev']);
      }
    },

    call(){
      if(this.state_function.boolean === true){
        console.log('here')
      }
    }
  },
  watch: {
    // whenever question changes, this function will run
    state_function: function (newQuestion, oldQuestion) {
      console.log(newQuestion)
      console.log(oldQuestion)

    }
  },
  computed: {
    state_function(){
      if(store.state.venues.call.boolean === true){
        console.log('here')
      }
      return store.state.venues.call
    },
    features(){
      return store.state.venues.acts
    },
    popup() {
      return new mapboxgl.Popup({
        closeButton: false
      });
    },
    filterEl() {
      return this.$refs.featurefilter
    },
    normalize(string) {
      return string.trim().toLowerCase();
    },

  }

}
</script>
  <style>
#map {
  position: absolute;
  left: 25%;
  top: 0;
  bottom: 0;
  width: 75%;
}
.map-overlay {
  position: absolute;
  width: 48.5%;
  height: 43.5vh;
  top: 4.2rem;
  bottom: 0;
  left: 1.2rem;
  font: 12px/20px "Helvetica Neue", Arial, Helvetica, sans-serif;
  background-color: #222222;
  max-height: 100%;
  overflow: hidden;
}

.map-overlay fieldset {
  display: none;
  background: #ddd;
  border: none;
  padding: 10px;
  margin: 0;
}

.map-overlay input {
  display: block;
  border: none;
  width: 100%;
  border-radius: 3px;
  padding: 10px;
  margin: 0;
  box-sizing: border-box;
}

.map-overlay .listing {
  overflow: auto;
  max-height: 100%;
}

.map-overlay .listing > * {
  display: block;
  padding: 5px 10px;
  margin: 0;
}

.map-overlay .listing a {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: #404;
  text-decoration: none;
}

.map-overlay .listing a:last-child {
  border: none;
}

.map-overlay .listing a:hover {
  background: #f0f0f0;
}
</style>