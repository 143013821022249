import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import VueMobileDetection from 'vue-mobile-detection'
Vue.use(VueMobileDetection)

import CountryFlag from 'vue-country-flag'

Vue.component('country-flag', CountryFlag)

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import VueLocalForage from 'vue-localforage'
Vue.use(VueLocalForage)

import vueCountryRegionSelect from 'vue-country-region-select'
Vue.use(vueCountryRegionSelect)

import LoadScript from 'vue-plugin-load-script';

Vue.use(LoadScript);

import VueYoutube from 'vue-youtube'
 
Vue.use(VueYoutube);

Vue.config.productionTip = false

import VueAnalytics from 'vue-analytics';

Vue.use(VueAnalytics, {
  id: 'UA-176108492-5',
  router
})



new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
