<template>
    <div>
        <v-card class="card-transparent mx-auto" max-width="900" v-if="is_basic_registered">
            <v-btn
                  v-show="!hidden"
                  color="primary"
                  fab
                  @click="openDialoge"
                  dark
                  small
                  absolute
                  top
                  right
                >
                  <v-icon>mdi-circle-edit-outline</v-icon>
                </v-btn>
            <v-card-title>Basic User Info</v-card-title>
            <v-row>
                <v-col md="6"><v-card-text>
                <span class="subtitle font-weight-bold">Inititaive Name:</span>
                {{ this.user_info_basic.init_name }}
                <br />

                <span class="subtitle font-weight-bold">Description:</span>
                {{ this.user_info_basic.init_description }}
                <br />
                <span class="subtitle font-weight-bold">Mission:</span>
                {{ this.user_info_basic.init_mission }}
                <br />
                <span class="subtitle font-weight-bold">Relief Type:</span>
                {{ this.user_info_basic.init_relief_type }}
                <br />
                <span class="subtitle font-weight-bold">Acts:</span>
                {{ this.user_info_basic.init_act }}
            </v-card-text></v-col>
                <v-col md="6" ><v-card-text>
                    <v-img max-width="150" class="ma-auto" :src="this.user_info_basic.init_logo_url"></v-img>
            </v-card-text></v-col>
            </v-row>
            
            <v-card-text>
                <span class="subtitle font-weight-bold">Country:</span>
                {{ this.user_info_basic.init_country }}
                <br />
                <span class="subtitle font-weight-bold">City:</span>
                {{ this.user_info_basic.init_city }}
                <br />
                <span class="subtitle font-weight-bold">Phone Number:</span>
                {{ this.user_info_basic.init_phone_number }} <br />
                <span class="subtitle font-weight-bold">Website:</span>
                {{ this.user_info_basic.init_website }}
            </v-card-text>
            <v-card-text>
                <span class="subtitle font-weight-bold">Incorperated?</span>
                {{ this.user_info_basic.init_incorporated }}
                <br />
                <span
                    class="subtitle font-weight-bold"
                    v-if="this.user_info_basic.init_incorporated"
                >Incorperated Type</span>
                {{ this.user_info_basic.init_incorporation_type }}
                <br />
                <span class="subtitle font-weight-bold">Core Team Members:</span>
                {{ this.user_info_basic.init_number_core_team }}
                <br />
                <span class="subtitle font-weight-bold">Founding Date:</span>
                {{ this.user_info_basic.init_founding_date }}
            </v-card-text>
          
            <v-card-text>
                <div v-if="video">
                                    <iframe
                                        width="100%"
                                        height="315"
                                        :src="videodisp"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                    ></iframe>
                                </div>
            </v-card-text>
            
        </v-card>
    </div>
</template>
<script>
import store from '@/store'
export default {
    name: 'basic-information',
    props: {
        user_info: Object,
        user_info_basic: Object,
        is_basic_registered: Boolean
    },
    data(){
        return {
            video: false,
            menu: false,
            videodisp: ''
        }
    },
    mounted(){
        this.loadURL()
    },
    methods:{
        openDialoge(){
            store.dispatch('forms/openDialoge')
        },
        loadURL() {
            const youtubeEmbedTemplate = 'https://www.youtube.com/embed/'
            const url = this.user_info_basic.init_video_url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
            console.log("url", url)
            const YId = undefined !== url[2] ? url[2].split(/[^0-9a-z_/\\-]/i)[0] : url[0]
            console.log("YId", YId)
            if (YId === url[0]) {
                console.log("not a youtube link")
            } else {
                console.log("it's a youtube video")
            }
            const topOfQueue = youtubeEmbedTemplate.concat(YId)
            console.log("topOfQueue", topOfQueue)
            this.videodisp = topOfQueue
            this.video = true;
            console.log(this.videodisp)
            console.log(this.form.init_video_url)
        }
    }
}
</script>