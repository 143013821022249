<template>
<div>
  <div >  <VueTwitchPlayer height="290"  :channel="stream_info.channel_name"></VueTwitchPlayer>
</div>

</div>
</template>
  
  <script>
import VueTwitchPlayer from 'vue-twitch-player';
import store from '@/store'
export default {
  // ...
  name: 'TwitchStream',
  components: {
    VueTwitchPlayer,
  },
  data() {
    return {
      channel: 'ardkon',
      videoId: 'cyFDA6lbBPM',
      playerVars: {
        autoplay: 1
      }
    }
  },
  methods: {
    playing() {
      console.log('/ we are watching!!!')
    },
    playNew(){
      this.player.play()

    }
  },
  computed: {
    
    stream_info(){
      return store.state.streaming.active_stream

    },
    player() {
      return this.$refs.youtube.player
    }
  }
  // ...
};
</script>