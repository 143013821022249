<template>
    <div>
     
        <v-row class="my-2" v-if="is_signed === true">
           
                <v-col cols="12">
                    <BasicInformation :user_info="getInitiative_details" :is_basic_registered="is_basic_registered" :user_info_basic="getBasic_details"></BasicInformation>

                <v-dialog v-model="open_dialog" max-width="900">
                    <edit-basic-information v-if="is_basic_registered ==='true'" :user_info = "getBasic_details"></edit-basic-information>
                <edit-basic-information-registered v-else :user_info = "getInitiative_details"></edit-basic-information-registered>
           
                </v-dialog>
                 </v-col>
        </v-row>
    </div>
</template>
<script>
import EditBasicInformation from '@/components/Initiatives/Dashboard/EditBasicInformation.vue'
import EditBasicInformationRegistered from '@/components/Initiatives/Dashboard/EditBasicInformationRegistered.vue'
import BasicInformation from '@/components/Initiatives/Dashboard/BasicInformation.vue'
import store from '@/store'
export default {
    components: {
        EditBasicInformation,
        BasicInformation,
        EditBasicInformationRegistered
    },
    methods: {
        getUserInfo() {

            store.dispatch('forms/getInitiativeStoreDetails')
            store.dispatch('forms/getInitiativeBasicStoreDetails')
            console.log(this.user_info)
        },
    },
    data() {
        return {
            user_info: {}
        }
    },
    name: 'initiatives-dashboard',
    created() {
        this.getUserInfo()
    },
    computed: {
        open_dialog() {
            return store.state.forms.open_dialog
        },  getInitiative_details() {
            return store.state.forms.user_info
        },
       getBasic_details() {
            return store.state.forms.user_info_basic
        },
      
        is_signed() {
            return store.state.forms.is_signed
        }, is_basic_registered() {
            return store.state.forms.is_basic_registered
        }
    }
}
</script>