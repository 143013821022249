<template>
<div>
    <v-card @click="initiative.initiative_dialogue = true" class="card-initiative ma-1">
<v-row no-gutters>
    <v-col cols="3" class="image-card">
        <v-img  height="100%" width="100%" :src="initiative.initiative_img_url"></v-img>
    </v-col>
    <v-col class="my-auto " md="6">
        <v-card-text class="text-caption font-weight-bold ">
            {{initiative.initiative_title}}
        </v-card-text>
    </v-col>
    <v-col class="my-auto " cols="3">
        <v-btn x-small color="primary">support</v-btn>
    </v-col>
</v-row>
</v-card>
<v-dialog max-width="420" v-model="initiative.initiative_dialogue">
    <v-card class=" ma-1">
        <v-card-text>
<v-row >
    <v-col cols="4" class="my-auto ">
        <v-img  height="100%" width="100%" :src="initiative.initiative_img_url"></v-img>
    </v-col>
    <v-col class="my-auto " md="8">
        <v-card-title class=" font-weight-bold ">
            {{initiative.initiative_title}}
            
        </v-card-title>
        <v-card-text>
            {{initiative.initiative_small_description}}

        </v-card-text>
    </v-col>
<v-col cols="12">
    {{initiative.initiative_description}}

</v-col>
</v-row>

<v-row>
    <v-col cols="12">
        <paypal-view  :initiative_id="initiative.initiative_id"> </paypal-view>
    </v-col>
</v-row>
</v-card-text>

</v-card>
</v-dialog>
</div>

</template>
<script>
 import PaypalView from '../Wallet1/PaypalView'

export default {
    name: 'Initiative',
    components:{
        PaypalView
    },
    props:{
        initiative: Object
    },
    data(){
        return {
            product: {
              price:10,
              description: 'Donated to action'
            },
            actions:[
                {
                    initiative_action_id: '155632vwd-dw4w544dw-dw44d7w',
                    initiative_action_dialogue: false,
                    initiative_action_name: 'initiative_action Name',
                    initiative_action_small_description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                    initiative_action_description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?',
                    initiative_action_linked_in_url: '',
                    initiative_action_website_url: '',
                    initiative_action_instagram_url: '',
                    initiative_action_facebook_url: '',
                    initiative_action_img_src:'https://ard-platform.s3.eu-central-1.amazonaws.com/profile-members/hero-to-back-1614070289684.png'
                },  {
                    initiative_action_id: '155632vwd-dw4w544dw-dw44d7w',
                    initiative_action_dialogue: false,
                    initiative_action_name: 'initiative_action Name',
                    initiative_action_small_description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                    initiative_action_description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?',
                    initiative_action_linked_in_url: '',
                    initiative_action_website_url: '',
                    initiative_action_instagram_url: '',
                    initiative_action_facebook_url: '',
                    initiative_action_img_src:'https://ard-platform.s3.eu-central-1.amazonaws.com/profile-members/hero-to-back-1614070289684.png'
                }, {
                    initiative_action_id: '155632vwd-dw4w544dw-dw44d7w',
                    initiative_action_dialogue: false,
                    initiative_action_name: 'initiative_action Name',
                    initiative_action_small_description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                    initiative_action_description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?',
                    initiative_action_linked_in_url: '',
                    initiative_action_website_url: '',
                    initiative_action_instagram_url: '',
                    initiative_action_facebook_url: '',
                    initiative_action_img_src:'https://ard-platform.s3.eu-central-1.amazonaws.com/profile-members/hero-to-back-1614070289684.png'
                },
            ]
        }
    }
}
</script>
<style>
.card-initiative{
    max-height:4.5rem;
    overflow-y:hidden;
    border-radius: 0px!important;
  transition: transform .2s; /* Animation */
  background-color: rgba(226,229,232,.63)!important;

}
.card-initiative:hover{
    transform: scale(0.9);
}
.image-card{
    height: 4.5rem;

}

.image-card:hover{
    transform: scale(1.2);
}
.actions{
    max-height:15rem;
    overflow-x: hidden;
    overflow-y: scroll;

}
</style>