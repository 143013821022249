<template>
    <div>
        <v-card class="mx-auto " max-width="900">
           
                <v-card-text>
                    <v-card-title>
                Initiative Basic Info Registered
            </v-card-title>
                    <v-spacer></v-spacer>
                    <v-row>
                        <v-col md="6" cols="12">
                            <v-card-text>
                                <v-text-field
                                    v-model="form.init_name"
                                    label="Initiative Name"
                                    required
                                ></v-text-field>
                                <v-textarea v-model="form.init_description" color="teal">
                                    <template v-slot:label>
                                        <div>Initiative Description</div>
                                    </template>
                                </v-textarea>
                            </v-card-text>
                        </v-col>
                        <v-col md="6" cols="12">
                            <v-card-text>
                                <v-text-field type="date" required v-model="form.init_founding_date" placeholder="Date of Birth"></v-text-field>

                                <v-text-field v-model="form.init_website" color="teal">
                                    <template v-slot:label>
                                        <div>
                                            Website
                                            <small>(optional)</small>
                                        </div>
                                    </template>
                                </v-text-field>
                                <v-text-field v-model="form.init_phone_number" color="teal">
                                    <template v-slot:label>
                                        <div>
                                            Phone Number
                                            <small>(optional)</small>
                                        </div>
                                    </template>
                                </v-text-field>
                            </v-card-text>
                            <v-row>
                                <v-col cols="12" >
                                   <v-card-text>your logo:</v-card-text>
                                    <v-img :src="form.init_logo_url" contain max-width="100">
                                        <template v-slot:placeholder>
                                            <v-row
                                                class="fill-height ma-0"
                                                align="center"
                                                justify="center"
                                            >
                                                <v-progress-circular
                                                    indeterminate
                                                    color="grey lighten-5"
                                                ></v-progress-circular>
                                            </v-row>
                                        </template>
                                    </v-img>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col md="6" cols="12">
                            <v-card-text>
                                <country-select
                                    v-model="form.init_country"
                                    class="v-label v-input__slot pt-6"
                                    :country="form.init_country"
                                    topCountry="LB"
                                />
                                <hr />
                                <v-text-field v-model="form.init_city" color="teal">
                                    <template v-slot:label>
                                        <div>
                                            Initiative City
                                            <small>(optional)</small>
                                        </div>
                                    </template>
                                </v-text-field>
                            </v-card-text>
                        </v-col>
                        <v-col md="6" cols="12">
                            <v-card-text>
                                <p>upload new Logo</p>

                                <v-file-input
                                    label="File input"
                                    filled
                                    v-model="form_1.logo"
                                    prepend-icon="mdi-camera"
                                    accept="image/png, image/jpg, image/jpeg"
                                    @change="imageToBase64"
                                ></v-file-input>
                                <span class="success--text">{{ url_message }}</span>
                            </v-card-text>
                        </v-col>
                        <v-col cols="12" md="12">
                            <v-card-text>
                                The type of your Initiative: {{form.init_type}}
                                <v-select
                                    label="Initiative Type"
                                    :items="init_types"
                                    v-model="form.init_types"
                                    data-vv-name="select"
                                    required
                                ></v-select>
                                <v-text-field
                                    v-if="form.init_types === 'other'"
                                    label="specifications, if needed"
                                    v-model="form.init_relief_type"
                                    required
                                ></v-text-field>
                            </v-card-text>
                            <v-divider class="my-5 mx-10" inset></v-divider>
                            <v-card-subtitle>Basic Information</v-card-subtitle>
                        </v-col>
                        <v-col cols="12" md="12">
                            <v-card-text>
                                <v-textarea
                                clearable
                                clear-icon="mdi-close-circle"
                                label="Initiative Mission"
                                v-model="form.init_mission"
                                @click="form.init_mission = ''"
                            ></v-textarea>
                            </v-card-text>
                           
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-card-text>Please specify the number of core team members in your initiative:</v-card-text>
                        </v-col>
                        <v-col cols="12" md="1">
                            <v-text-field v-model.number="form.init_number_core_team" class="text-center"></v-text-field>
                        </v-col>
                        <v-col col="12" md="3">
                            <v-card-text>Which acts do you feel your work is most aligned with?</v-card-text>
                        </v-col>
                        <v-col col="12" md="4">
                            <v-select
                                :items="acts"
                                v-model="form.init_act"
                                multiple
                                attach
                                small-chips
                                deletable-chips
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-card-text>Is your initiative incorporated?</v-card-text>
                        </v-col>
                        <v-col cols="12" md="1">
                            <v-select :items="['Yes', 'No']" label="Select" v-model="incorporated"></v-select>
                        </v-col>
                        <v-col cols="12" md="12">
                            <v-card-text
                                v-if="incorporated === 'Yes'"
                            >Please select the type of incorporation.</v-card-text>
                            <v-select
                                v-if="incorporated === 'Yes'"
                                :items="incorporation_types"
                                v-model="form.init_incorporation_type"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-card-text>Add your video url
                            <v-text-field v-model="form.init_video_url" label="Video URL"></v-text-field>
                            <v-btn class="mr-4" color="red darken-4" @click="loadURL">Load Video</v-btn>
                        </v-card-text>
                        </v-col>
                        <v-col cols="12" md="6">
                            <template>
                                <div v-if="video">
                                    <iframe
                                        width="100%"
                                        height="315"
                                        :src="videodisp"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                    ></iframe>
                                </div>
                            </template>
                        </v-col>
                    </v-row>

                    <v-progress-linear
                        v-if="loadingBar"
                        color="info accent-4"
                        indeterminate
                        rounded
                        height="6"
                    ></v-progress-linear>
                    <br />

                    <v-card-text>
                        <span class="error--text">{{ message_error }}</span>
                        <br />
                        <v-btn class="mr-4" color="primary" @click="submit">Save Section</v-btn>
                    </v-card-text>
                </v-card-text>
                <v-divider></v-divider>
        </v-card>
    </div>
</template>

<script>
import store from '@/store'
export default {
    name: 'edit-basic-information-registered',
    components: {
        
    },
    data() {
        return {

            init_types: ['Reconstruction', 'Medical Support', 'Psychological Support', 'Food support', 'other'],
            acts: ['We do no harm', 'We live together', 'We drink the same', 'We feed all', 'We travel slow', 'We work for all', 'We share what we know', 'We pray as one', 'We rebel'],
            incorporation_types: ['Company', 'NGO'], //To be checked
            form_1: {
                logo: {},
                init_logo_url: this.user_info.init_logo_url
            },
            message_error: '',
            logo_image: this.user_info.init_logo_url,
            incorporated: 'No',
            form: {
                init_name: this.user_info.init_name,
                init_country: this.user_info.init_country,
                init_city: this.user_info.init_city,
                init_founding_date: this.user_info.init_founding_date,
                init_description: this.user_info.init_description,
                init_mission:  this.user_info.init_mission,
                init_number_core_team: this.user_info.init_number_core_team,
                init_incorporated: (this.user_info.init_incorporated === 'true'),
                init_incorporation_type: this.user_info.init_incorporation_type,
                init_relief_type: this.user_info.init_type,
                init_video_url: this.user_info.init_video_url,
                init_act: this.user_info.init_act,
                init_phone_number: this.user_info.init_phone_number,
                init_logo_url: this.user_info.init_logo_url,
                init_types: this.user_info.init_type,
                init_website: this.user_info.init_website,
                campaign_id: "4644c74b-8afc-4a9a-b355-e89d7c06914b",
                user_id: this.user_info.user_id,
                init_id: this.user_info.init_id
            },
            video: false,
            menu: false,
            videodisp: ''
        }

    },
    props:{
        user_info: Object
    },
    computed: {
        message() {
            return store.state.forms.message_form
        },
        loadingBar() {
            return store.state.forms.loading_bar
        },
        url_message() {
            return store.state.forms.message
        },
        initiative_img_url() {
            return store.state.forms.img_uploaded
        }
    },
    methods: {
        async check() {
            store.dispatch('forms/loadingTrue')
            if(this.url_message === 'uploaded'){
                this.form.init_logo_url = this.initiative_img_url

            }
            if (this.form.init_relief_type === '') {
                this.form.init_relief_type = this.form.init_types
            } if (this.incorporated_word === 'Yes') {
                this.form.init_incorporated = true;
            }
            return true
        },
        async submit() {
            this.check().then((result) => {
                console.log(result)
                if (this.form.init_name != '' & this.form.init_description != '' & this.form.init_country != ''  & this.form.init_relief_type != '') {
                    store.dispatch('forms/initiativeBasicInfoForm', this.form)
                    this.message_error = ""
                } else {
                    store.dispatch('forms/loadingFalse')
                    if (this.form.init_name === '') {
                        this.message_error = "add initiative name"
                    } if (this.form.init_country === '') {
                        this.message_error = "add initiative country"
                    } if (this.form.init_description === '') {
                        this.message_error = "add initiative short description"
                    }  if (this.form.init_relief_type === '') {
                        this.message_error = "add initiative type"
                    }
                }
            })

            console.log(this.form)

        },
        imageToBase64() {
            var reader = new FileReader()
            reader.readAsDataURL(this.form_1.logo)
            reader.onload = () => {
                this.form_1.init_logo_url = reader.result.split(',')[1]
                store.dispatch('forms/uploadImage', { image: this.form_1.init_logo_url })
            }
            reader.onerror = function (error) {
                console.log('Error: ', error)
            }
        },
        loadURL() {
            const youtubeEmbedTemplate = 'https://www.youtube.com/embed/'
            const url = this.user_info.init_video_url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
            console.log("url", url)
            const YId = undefined !== url[2] ? url[2].split(/[^0-9a-z_/\\-]/i)[0] : url[0]
            console.log("YId", YId)
            if (YId === url[0]) {
                console.log("not a youtube link")
            } else {
                console.log("it's a youtube video")
            }
            const topOfQueue = youtubeEmbedTemplate.concat(YId)
            console.log("topOfQueue", topOfQueue)
            this.videodisp = topOfQueue
            this.video = true;
            console.log(this.videodisp)
            console.log(this.form.init_video_url)
        }
    }
}
</script>