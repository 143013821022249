<template>
  <youtube height="290" width="100%"  :video-id="stream_info.channel_name" :player-vars="playerVars"  resize ref="youtube"></youtube>


</template>
  
  <script>
import store from '@/store'
export default {
  // ...
  name: 'YoutubeStream',
  components: {
  },
  data() {
    return {
      channel: 'ardkon',
      videoId: 'cyFDA6lbBPM',
      playerVars: {
        autoplay: 0
      }
    }
  },
  methods: {
    playing() {
      console.log('/ we are watching!!!')
    },
    playNew(){
      this.player.play()

    }
  },
  computed: {
    
    stream_info(){
      return store.state.streaming.active_stream

    },
    player() {
      return this.$refs.youtube.player
    }
  }
  // ...
};
</script>