<template>
<div >
    <v-app-bar dense color="rgba(32, 63, 94, .69)">
        <h4 class="text-center white--text font-weight-bold">
            broadcast
          
        </h4>
        <v-spacer></v-spacer> 
        <v-tooltip
          v-model="show"
          color="primary"
          top
          max-width="210"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="partners_dialog = true"

            >
            <v-icon small
      color="white darken-2" >
                mdi-alert-circle-outline
            
          </v-icon>
            </v-btn>
          </template>
          <span></span>
          <h4>
              Broadcast
          </h4>
          <p>
              The Broad cast Section is allowing users of different types to stream or broadcast content on the platform
          </p>
        </v-tooltip>
        <v-icon
      small
      color="white darken-2"
    >
    </v-icon>


<v-spacer></v-spacer>
<v-spacer></v-spacer>
<v-spacer></v-spacer>
<v-spacer></v-spacer>
<v-spacer></v-spacer>
<v-spacer></v-spacer>
<v-spacer></v-spacer>
        <v-spacer></v-spacer>
            <v-btn x-small outlined color="white "  @click="reporters_dialog = true"><span class="text-lowercase">add</span></v-btn>
    </v-app-bar>
    <v-row style="height:42vh; overflow-y: scroll" >
        
        <v-col cols="12">
            <Video  v-for="stream in streams" :key="stream.stream_name" class="stream-card" :video="stream"></Video>
        </v-col>
        </v-row>
    <v-dialog v-model="reporters_dialog" max-width="900">
        <v-card>
            <v-card-title>broadcast</v-card-title>
            <v-card-text> let’s talk about Beirut, dance for Beirut, perform for Beirut: find the latest live events of the creative community worldwide and panels that discuss the next steps for sustainable solutions that serve everyone in the city and bridge the gaps between Beirut, the diaspora & the global supportive community…
To create an event, fill out the register for the acts 
            </v-card-text>
            <v-card-title >
                <span class="mx-auto">choose type of broadcasting</span>
            </v-card-title>
            
        
            <RepotersRegistrations></RepotersRegistrations>

        </v-card>
    </v-dialog>
   
      
         
    
       
</div>
 

   
</template>
  
  <script>
import Video from './video'
import store from '@/store'
import RepotersRegistrations from './RepotersRegistrations.vue'
export default {
    // ...
    name: 'MainStream',
    components: {
    Video,
        RepotersRegistrations
    },
    data() {
        return {
            reporters_dialog: false,
            channel: 'ardkon',
            stream_reg_type: '',
            streams_s: [
                {
                    video_name: 'voices LI BEIRUT',
                    stream_type: 'facebook',
                    channel_name: 'https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2F106260317399870%2Fvideos%2F2791777357814500%2F&show_text=false&width=560&t=0',
                    video_img_src: 'https://ard-platform.s3.eu-central-1.amazonaws.com/profile-members/hero-to-back-1627220000884.png'
                }
            ]
        };
    },
    computed:{
        streams(){
            return store.state.streaming.streams
        }
    },

    mounted(){
        store.dispatch('streaming/channelStatus',{channel_name: 'ardkon'})
    }
    // ...
};
</script>
<style>
.stream-card {
    border-radius: 0px !important;
    background-color: rgba(243,243,243,.63)!important;

}
.type-card {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.11);
  background-color: rgba(255, 255, 255, 0.63) !important;
  height: 12vh;
  cursor: pointer;
}

.type-card:hover {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.11);
  background-color: #40454c !important;
  color: white !important;
  cursor: pointer;
}
</style>