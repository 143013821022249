<template>
<div class="ma-4">
    <v-card class="card-transparent">
      <art-fest />

    </v-card>

</div>
</template>

<script>
import ArtFest from '@/components/ArtFest/ArtFest.vue'

  export default {
    name: 'ArtFestival',

    components: {
      ArtFest,
    },
    mounted(){
      document.title = 'Art Fest'
    }
  }
</script>
