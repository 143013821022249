<template>
  <div>
    <v-app-bar app color="primary" dark>
      <h3>Campains</h3>
    </v-app-bar>
<br>
<v-container>
  <v-row> 
      <v-col md="6" cols="!2">
        <v-card  style="border-radius: 3px; cursor: pointer;">
          <v-card-title @click="goToLiBeirut">
            <v-row>
              <v-col cols="4" style="width:12rem; height:12rem; ">
                <v-img contain width="9rem" height="9rem" :src="libeirutimg"></v-img>
              </v-col>
              <v-col cols="8" class="my-auto">
                LiBeirut لبيروت

              </v-col>
            </v-row>

          </v-card-title>
    </v-card>
      </v-col>
    </v-row>
</v-container>
   
   
  </div>
</template>

<script>
import libeirutimg from '@/assets/sticker.jpeg'
export default {
  name: 'Home',
  data(){
    return {
      libeirutimg: libeirutimg
    }
  },
  methods:{
    goToLiBeirut(){
      this.$router.push({ path: 'action/LiBeirut' })
    }
  },
  components: {
  },
}
</script>
