<template>
<div>
    <v-card @click="item.item_dialogue = true" class="card-item-ledger ma-1">
        <v-card-text>

<v-row no-gutters>
    <v-col cols="2" class="image-card-ledger my-auto" >
            <v-icon color="success">mdi-finance</v-icon>

        
    </v-col>
    <v-col  class="my-auto" cols="8">
      <span class="caption font-weight-bold ">
        {{item.name}}

      </span>
       
       
    </v-col><v-col class="my-auto"  cols="2">
        <span class="caption font-weight-bold red--text">
            {{item.transaction_value}} {{item.transaction_currency}}
      </span> 
           
    </v-col>
    <v-col class="my-auto " cols="3">
        
    </v-col>
</v-row>
</v-card-text>
</v-card>
<v-dialog max-width="420" v-model="item.item_dialogue">
    <v-card class=" ma-1">
        <v-card-text>
<v-row>
    <v-col cols="4" class="my-auto ">
        <v-img  height="100%" width="100%" :src="item.item_img_src"></v-img>
    </v-col>
    <v-col class="my-auto " md="8" >
        <v-card-text class="text-caption font-weight-bold ">
            {{item.item_name}}
            
        </v-card-text>
        <v-card-text>
            {{item.item_small_description}}

        </v-card-text>
    </v-col>

</v-row>
<v-row>
    <v-col cols="12">

    </v-col>
</v-row>
</v-card-text>

</v-card>
</v-dialog>
</div>

</template>
<script>
export default {
    name: 'ledger-item',
    props:{
        item: Object
    }
}
</script>
<style>
.card-item-ledger{
    max-height:4.5rem;
    overflow-y:hidden;
    border-radius: 0px!important;
  transition: transform .2s; /* Animation */

}
.card-item-ledger:hover{
    transform: skew(-10deg,0.9deg);
    transform: scale(0.9);
}
.image-card-ledger{
    height: 3rem;

}

.image-card-ledger:hover{
    transform: scale(1.2);
}
</style>