<template>
<div>
    <v-row>
        <v-col>
            <v-card>
                <v-card-title>
                   my fund allocations
                </v-card-title>


                <v-card-text>

                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <AddFundAllocation></AddFundAllocation>

</div>
</template>
<script>
import AddFundAllocation from './AddFundAllocation'
export default {
  components: { AddFundAllocation },
    name: 'fund-allocations',
    componenets: {
        
        AddFundAllocation
    }
}
</script>