import axios from 'axios'
import Cookies from "js-cookie";
import router from '../router';
import localforage from 'localforage';

const actions = {
    uploadImage({ commit }, form) {
        return axios
            .post("https://apiss.ardkon.com/profile_image_upload?hello=f", form)
            .then((response) => {
                console.log(response)
                if (response.data.message === "Success") {
                    commit("IMAGE_UPLOAD", response.data.data);
                } else {
                    console.log(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, uploadImage1({ commit }, form) {
        return axios
            .post("https://apiss.ardkon.com/profile_image_upload?hello=f", form)
            .then((response) => {
                console.log(response)
                if (response.data.message === "Success") {
                    commit("IMAGE_UPLOAD_1", response.data.data);
                } else {
                    console.log(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    },
    loadingTrue({commit}){
        commit('MUTATE_LOADING')

    }, openDialoge({commit}){
        commit('OPEN_DIALOG')

    },loadingFalse({commit}){
        commit('MUTATE_LOADING_FALSE')

    },
    async getInitiativeStoreDetails({commit}){
        localforage.getItem('user_info').then(function(value) {
            // This code runs once the value has been loaded
            // from the offline store.
            commit('SET_USER_INFO_STORE', value)
            return value;
        }).catch(function(err) {
            // This code runs if there were any errors
            return err;
        });
        
        localforage.getItem('is_signed_in').then(function(value) {
            // This code runs once the value has been loaded
            // from the offline store.
            commit('SET_USER_SIGNED', value)
            return value;
        }).catch(function(err) {
            // This code runs if there were any errors
            return err;
        });
         
    }, async getInitiativeBasicStoreDetails({commit}){
        localforage.getItem('user_info_basic').then(function(value) {
            // This code runs once the value has been loaded
            // from the offline store.
            commit('SET_BASIC_USER_INFO_STORE', value)

            return value;
        }).catch(function(err) {
            // This code runs if there were any errors
            return err;
        });
        localforage.getItem("basic_info_registered").then(function(value) {
            
            commit('SET_BASIC_USER_INFO_STORE_BOOL', value)

            return value
        }).catch(function(err) {
        // This code runs if there were any errors
        return err;
    });
        
         
    },
    initiativeRegistration({ commit }, form) {
        return axios
            .post("https://apiss.ardkon.com/RegisterInitiative?basics=campaign2", form)
            .then((response) => {
                console.log(response)
                if (response.data.message === "Success") {
                    commit("FORM_UPLOADED_INITIATIVE", response.data.data);
                    Cookies.set("is_signed_in", true);
                    localforage.setItem("user_signed_in", true);
                    localforage.setItem("user_info", response.data.data);
                    router.push({name: 'DashboardInitiative'})
                    
                } else {
                    console.log(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, initiativeBasicInfoForm({ commit }, form) {
        return axios
            .post("https://apiss.ardkon.com/InitDashboardBasicInformation?basics=campaign2", form)
            .then((response) => {
                console.log(response)
                if (response.data.message === "Success") {
                    commit("FORM_UPLOADED_INITIATIVE", response.data.data);
                    Cookies.set("basic_info_registered", true);
                    localforage.setItem("basic_info_registered", true);
                    localforage.setItem("user_info_basic", response.data.data);
                    
                } else {
                    console.log(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    },
    liBeirutForm({ commit }, form) {
        return axios
            .post("https://apiss.ardkon.com/libeirut?hello=ss", form)
            .then((response) => {
                console.log(response)
                if (response.data.message === "success") {
                    commit("FORM_UPLOADED", response.data.data);
                } else {
                    console.log(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    },
     streamForm({ commit }, form) {
        return axios
            .post("https://apiss.ardkon.com/RegisterBroadcast?hello=ss", form)
            .then((response) => {
                console.log(response)
                if (response.data.message === "success") {
                    commit("STREAM_ADDED", response.data.data);
                } else {
                    console.log(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    },
    
    artworkForm({ commit }, form) {
        return axios
            .post("https://apiss.ardkon.com/ArtWork?hello=ss", form)
            .then((response) => {
                console.log(response)
                if (response.data.message === "success") {
                    commit("FORM_UPLOADED", response.data.data);
                } else {
                    console.log(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    },
}

const forms = {
    namespaced: true,
    state: {
        img_uploaded: '',
        open_dialog: false,
        message:'',
        message_poster:'',
        poster_uploaded:'',
        message_form: '',
        loading_bar: false,
        user_info: {},
        is_signed: false,
        is_basic_registered: false,
        user_info_basic:{}
    
    },
    actions,
    mutations: {
        OPEN_DIALOG(state){
            state.open_dialog = true
        },
        SET_BASIC_USER_INFO_STORE_BOOL(state, value){
            state.is_basic_registered = value
        },  SET_USER_SIGNED(state, value){
            state.is_signed = value
        },  SET_BASIC_USER_INFO_STORE(state, value){
            state.user_info_basic = value
        },
        SET_USER_INFO_STORE(state, value){
            state.user_info = value
        },
        MUTATE_LOADING_FALSE(state){
            state.loading_bar = false

        }, MUTATE_LOADING(state){
            state.loading_bar = true

        }, FORM_UPLOADED_INITIATIVE(state){
            state.message_form = 'Success'
            state.loading_bar = false

        }, FORM_UPLOADED(state){
            state.message_form = 'Success'
            state.loading_bar = false

        },
        IMAGE_UPLOAD(state, data){
            state.img_uploaded = data.profile_pic_url
            state.message = 'uploaded'
        }, IMAGE_UPLOAD_1(state, data){
            state.poster_uploaded = data.profile_pic_url
            state.message_poster = 'uploaded'
        }
    }
}

export default forms;