<template>
    <div>
        <v-app-bar color="primary">
            <h3 class="white--text">Initiative Dashboard</h3>
        </v-app-bar>
        <v-row class="my-2" v-if="is_signed != true">
            <v-col cols="12">
                <LinkedIn></LinkedIn>
                <InitiativeSignIn></InitiativeSignIn>
            </v-col>
        </v-row>

        <v-row class="ma-2">
            <v-col cols="12">
                <edit-basic-information></edit-basic-information>
            </v-col>
        </v-row> <v-row class="ma-2">
            <v-col cols="12">
                <FundAllocations></FundAllocations>
                <FundAllocation></FundAllocation>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import LinkedIn from '../components/SignUp/LinkedIn'
import FundAllocations from '../components/Initiatives/Dashboard/FundAllocations'
import FundAllocation from '../components/Initiatives/Dashboard/FundAllocation'
import InitiativeSignIn from '../components/Initiatives/Dashboard/InitiativeSignIn'
import EditBasicInformation from '../components/Initiatives/Dashboard/EditBasicInformation'
export default {
    components: {
    LinkedIn,
    FundAllocations,
        InitiativeSignIn,
        EditBasicInformation,
        FundAllocation
    },
    name: 'dashboard'
}
</script>