<template>
    <div>
       
                   <v-row>
                 






                
                <v-col md="12" cols="12">
                    <v-card-title>ARTWORKS</v-card-title>

                    <v-card-subtitle >
                     
                        Let’s weave the fabric of new beginnings,<br>
Work the net of a collective creativity
                            <br><br />Ardkon & Agro-Art Collective are inviting makers and communities for a summit festival to brainstorm what the community needs
<br>in the old horse stables at Ard Ardkon in Delb/Bikfaya we will open an artist village – each room can host performances, workshops, movie screenings, your gallery …
<br>our workshop calendar – dancing classes, movement classes, crafting workshops etc. 
<br>book exchange & writers corner 
<br>unstable room podcast 
<br>collective cashless economy 



                    </v-card-subtitle>

                </v-col>
                
             
                
            </v-row>

   

            <v-card-text class="text-center"></v-card-text>
            <v-card-text>
                <v-row>
               
                   
               
                    <v-col cols="12" md="8">
                        <v-card-text>
                           
                            <div >
                             <br>
                        </div>
                            <v-select
                                label="Choose one or more ways to participate in this event "
                                multiple
                                item-text="name"
                                item-value="description"
                                
                                return-object
                                :items="participation_types"
                                v-model="form.participation_types"
                                data-vv-name="select"
                                required
                            ></v-select>
                         
                            <v-row>
                                <v-col md="6" v-for="p_type in form.participation_types" :key="p_type.name" cols="12">
                                    <v-card style="min-height: 9rem">
                                        <v-card-title>
                                    {{p_type.name}}

                                </v-card-title>
                                <v-card-text>
                                    {{p_type.description}}

                                </v-card-text>
                            
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-col>
                         
                    <v-col md="4" cols="12">
                        <v-card-text>
                           <p>
                            add your image
                           </p> 
                            <v-file-input
                                label="File input"
                                filled
                                v-model="form_1.logo"
                                prepend-icon="mdi-camera"
                                accept="image/png, image/jpg, image/jpeg"
                                @change="imageToBase64"
                            ></v-file-input>
                            <span class="success--text">{{ url_message }}</span>
                        
                            
                               
                        </v-card-text>
                    </v-col>

                <v-col cols="12" md="8">
                        <v-card-text>
                            <v-select
                                label="Choose the ardkon acts that will match with your philosophy the most "
                                multiple
                                item-text="name"
                                item-value="description"
                                chips
                                return-object
                                :items="ardkon_acts"
                                v-model="form.ardkon_acts"
                                data-vv-name="select"
                                required
                            ></v-select>
                            
                             
                        </v-card-text>
                    </v-col>
                    <v-col md="4" cols="12">
                        <v-row>
                            <v-col cols="6" md="4" v-for="act in form.ardkon_acts" :key="act.name">
                                <v-card style="border-radius:18px; min-height: 9rem" 
:color="act.color_r">
                                <v-card-text :class="act.color">
                                    <span  class="mx-auto caprion">{{act.name}}
</span>
                                </v-card-text>
                                <v-card-text  :class="act.color">
                                    {{act.description}}

                                </v-card-text>
                            </v-card></v-col>
                        </v-row>
                      
                    </v-col>
                  
                    
                    
                    <v-col cols="12">


                        <v-card-title>personal contact information</v-card-title>
                        <v-card-text>
                            <v-text-field label="name" v-model="form.name" required></v-text-field>
                            <v-text-field
                                label="email address"
                                v-model="form.email_address"
                                required
                            ></v-text-field>
                            <v-text-field label="address" v-model="form.address" required></v-text-field>
                            <v-text-field label="phone number" v-model="form.phone_number" required></v-text-field>

                           
                           <v-text-field
                               label="social media or website link"
                               v-model="form.social_media_link"
                               required
                           ></v-text-field>
                        </v-card-text>
                        <v-card-text>
                            
                            Tell us about you or your group. Who are you and how do you come together (if you are more than one person)? What inspires your work? What would your presence contribute to this event and what do you hope to gain in participating?                            <v-textarea
                                                            label="your short artists bio"
                                                            required
                                                            rows="2"
                                                            v-model="form.short_description_artist"
                                                            clearable
                                                            clear-icon="mdi-close-circle"
                                                        ></v-textarea>
                                                    </v-card-text>
                    </v-col>
                    <v-col md="12" cols="12">
                     
                        <v-card-subtitle
                            class="caption"
                        >
                        <span class="red--text">{{ message }}</span>
                        <br />
                        </v-card-subtitle>
                    </v-col>
                </v-row>

                <v-progress-linear v-if="loadingBar" color="info accent-4" indeterminate rounded height="6"></v-progress-linear>
                <br />
                <v-row>
                    <v-col md="12">
                       
                        <v-card-text>
                           
ardkon.com & partners
                        </v-card-text>
                    </v-col>
                </v-row>
                <v-card-text>
                    <span class="error--text">{{message_error}}</span><br>
                    <v-btn class="mr-4"  color="primary"  @click="submit">submit</v-btn>
                </v-card-text>
                
            </v-card-text>
    </div>
</template>
<script>


import store from '@/store'
export default {
    name: 'art-fest',
    data() {
        return {
           
            checkbox: false,
            form_1: {
                logo: {},
                logo_url: '',
                poster:{},
                poster_url:''
            },
            participation_types:[
                    
                {
                    name: 'a room in the stables',
                    description: 'choose your room: plan/drone picture of the stables with slots. Choose a room to install your own gallery, workshop, or ongoing performance in one of the old horse stables. Each stable has an open air ceiling.'
                }, {
                    name: 'a performance in the program',
                    description: 'Here you can share any kind of performance you have been working on. It can be theater, dance, movement, expression, music, or anything else you believe falls under this theme.'
                },{
                    name: 'a stall for your items',
                    description: 'Here you can set up and sell your items open air in a small stall in the ardkon square.'
                },{
                    name: 'a class in our calendar',
                    description: 'Would you like to share your knowledge in a class or workshop? Yoga, movement, dance, dyeing, painting, juggling, expression? Anything! This is your chance to teach us!'
                },{
                    name: 'a slot in the cinema sessions',
                    description: 'Have you created a movie or documentary and wish to screen it? One stable room will be dedicated to screening movies during the festival. You can sign up your movie to be shown during the cinema schedule.'
                },
            {
                    name: 'a spot on a wall',
                    description: 'Ard Ardkon space is filled with walls and trees ready to hold your artworks. Would you like to take part in this by selecting a few of your items and hanging them around the space for the eye’s delight or for purchase.'
                },   {
                    name: 'a play in the open theatre',
                    description: 'Throughout the festival there will be time slots to showcase a performance, storytell, or a theater piece. '
                },  {
                    name: 'a shop in the digital market',
                    description: 'You can already start offering your items in the period before the festival summit as pre orders or for shipping.  '
                }, {
                    name: 'a gig in the lineup ',
                    description: 'lay music live or DJ for us! Everyone is ready to groove.  '
                },
            
            
            ],
            ardkon_acts: [
                    {
                        name: 'we do no harm',
                        description: 'eco-friendly concept ',
                        color: 'white--text',
                        color_r:'green'
                    }, {
                        name: 'we live together',
                        description: 'camping ground ',
                        color: 'white--text',
                        color_r:'warning'


                    },{
                        name: 'we drink the same',
                        description: 'bar ',
                        color: 'white--text',
                        color_r:'purple'


                    },{
                        name: 'we feed all',
                        description: 'kitchen ',
                        color: 'green--text',
                        color_r:'blue'


                    },{
                        name: 'we travel slow  ',
                        description: 'arrival & carpooling ',
                        color: 'black--text',
                        color_r:'yellow'


                    },{
                        name: 'we work for all',
                        description: 'workshops ',
                        color: 'white--text',
                        color_r:'light-blue'


                    },{
                        name: 'we share what we know',
                        description: 'stables ',
                        color: 'white--text',
                        color_r:'info'


                    },{
                        name: 'we pray as one',
                        description: 'music ',
                        color: 'grey--text',
                        color_r:'white'


                    },{
                        name: 'we rebel',
                        description: 'theatre & performances ',
                        color: 'white--text',
                        color_r:'red'


                    },
                ],
            message_error:'',
            form: {
                logo_url:'',
                ardkon_acts:[],
                participation_types:[],
                
                name:'',
                email_address:'',
                address: '',
                phone_number:'',
                short_description_artist:'',

                social_media_link:''


            }

        }

    },
  
    computed: {
        message(){
            return store.state.forms.message_form
        },
        loadingBar(){
            return store.state.forms.loading_bar
        },
        url_message() {
            return store.state.forms.message
        },poster_message() {
            return store.state.forms.message_poster
        },
        artist_img_url() {
            return store.state.forms.img_uploaded
        },
        venue_poster_url() {
            return store.state.forms.poster_uploaded
        }

    },
    methods: {

        async check(){
            store.dispatch('forms/loadingTrue')
            this.form.logo_url = this.artist_img_url
           
          
            

            return true
        },
       async submit(){

            this.check().then((result) =>{
                console.log(result)
                if(  this.form.ardkon_acts.length > 0 & this.form.participation_types.length > 0 & this.form.short_description_artist != '' & this.form.phone_number != '' & this.form.logo_url != ''   & this.form.name != '' & this.form.email_address != '' &      this.form.address != '' &   this.form.social_media_link != ''){
                store.dispatch('forms/artworkForm', this.form)
                this.message_error = ""


            } else {
                store.dispatch('forms/loadingFalse')

            
               if(this.form.ardkon_acts.length === ''){
                    this.message_error = "choose your acts"

                
               }  if(this.form.participation_types.length === ''){
                    this.message_error = "choose your participation "

                
               } if(this.form.phone_number === ''){
                    this.message_error = "add phone number"

                
               }if(this.form.short_description_artist === ''){
                    this.message_error = "add artist short description"

                }if(this.form.logo_url === ''){
                    this.message_error = "add artist image"

                }if(this.form.name === ''){
                    this.message_error = "add name"

                }if(this.form.email_address === ''){
                    this.message_error = "add email address"

                }if(this.form.address === ''){
                    this.message_error = "add address"

                }if(this.form.social_media_link === ''){
                    this.message_error = "add social media link"

                }

            }
            })
           

           


        },
        imageToBase64() {
            var reader = new FileReader()
            reader.readAsDataURL(this.form_1.logo)
            reader.onload = () => {


                this.form_1.logo_url = reader.result.split(',')[1]
                store.dispatch('forms/uploadImage', { image: this.form_1.logo_url })

            }
            reader.onerror = function(error) {
                console.log('Error: ', error)

            }
        }, imageToBase641() {
            var reader = new FileReader()
            reader.readAsDataURL(this.form_1.poster)
            reader.onload = () => {


                this.form_1.poster_url = reader.result.split(',')[1]
                store.dispatch('forms/uploadImage1', { image: this.form_1.poster_url })

            }
            reader.onerror = function(error) {
                console.log('Error: ', error)

            }
        }
    }
}
</script>