import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import ClubHouseIcon from '@/components/icons/ClubHouseIcon.vue'

Vue.use(Vuetify);


export default new Vuetify({

  icons: {
    values: {
      custom: { // name of our custom icon
        component: ClubHouseIcon, // our custom component
      },
    },
  },
    theme: {
        dark: false,
        
        themes: {
          light: {
            primary: "#203F5E",
            secondary: "#e2e5e8",
            accent: "#82B1FF",
            error: "#cb152b",
            info: "#369ad4",
            success: "#4CAf50",
            warning: "#FFC107",
            grey: "#808488",
            blue:'#203F5E'
          },
          dark: {
            primary: "#203F5E",
            secondary: "#203F5E",
            accent: "#82B1FF",
            error: "#FF5252",
            info: "#132F3E",
            success: "#00ab66",
            warning: "#FFC107",
            blue:'#203F5E'

          }
        }
      }
});
