<template>
<div>
  
  <div style="height:44vh" v-if="stream_info.stream_type === 'stream'">  
    
    <TwitchStream></TwitchStream>
</div><div v-if="stream_info.stream_type === 'video'">
<YoutubeStream></YoutubeStream>
</div><div v-if="stream_info.stream_type === 'facebook'">
<iframe :src="stream_info.channel_name" height="500" width="100%"></iframe></div>

</div>
</template>
  
  <script>
import YoutubeStream from './YoutubeStream'
import TwitchStream from './TwitchStream'
import store from '@/store'
export default {
  // ...
  name: 'MainStream',
  components: {
    YoutubeStream,
    TwitchStream,
  },
  data() {
    return {
      channel: 'ardkon',
      videoId: 'cyFDA6lbBPM',
      playerVars: {
        autoplay: 1
      }
    }
  },
  methods: {
    playing() {
      console.log('/ we are watching!!!')
    },
    playNew(){
      this.player.play()

    }
  },
  computed: {
    
    stream_info(){
      return store.state.streaming.active_stream

    },
    player() {
      return this.$refs.youtube.player
    }
  }
  // ...
};
</script>