<template>
<div>
    <v-app-bar  dense  color="rgba(32, 63, 94, .69)" >
<h3 class="white--text">
    initiatives     
 </h3><v-spacer></v-spacer> <v-tooltip
          v-model="show"
          color="primary"
          bottom
          max-width="210"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="partners_dialog = true"

            >
            <v-icon small
      color="white darken-2" >
                mdi-alert-circle-outline
            
          </v-icon>
            </v-btn>
          </template>
          <span></span>
          <h4>
              Initiatives
          </h4>
          <p>
            LI BEIRUT aims to empower sustainable and innovative solutions for ongoing challenges. Meet the grassroots initiatives in Beirut here or register with yours!
        </p>
        </v-tooltip>


<v-spacer></v-spacer>
<v-spacer></v-spacer>
<v-spacer></v-spacer>
<v-spacer></v-spacer>
<v-spacer></v-spacer>
<v-spacer></v-spacer>
<v-spacer></v-spacer>

<v-btn x-small outlined color="white "  ><span class="text-lowercase" @click="goToLinked">submit</span></v-btn>

</v-app-bar>
<v-row class="initiatives" no-gutters>
  
    <v-col v-for="initiative in initiatives" :key="initiative.initiative_id" cols="12">
        <Initiative :initiative="initiative"></Initiative>
    </v-col>
</v-row>
</div>
</template>
<script>
import Initiative from './Initiative.vue'
//import store from '@/store'
export default {
    name: 'Initiatives',
    components:{
        Initiative
    },
    data(){
        return {
            
            initiatives:[
              {
                    initiative_id: 'f70893ec-7a34-4560-839b-af618a83e181',
                    initiative_dialogue: false,

                    initiative_title: 'The Nation Station',
                    initiative_small_description: 'On August 5th 2020, the morning after the blast that took over 200 lives, injured thousands and displaced over 300,000, a group of local neighbors met at an abandoned gas station near Al Roum hospital to take stock of their shattered surroundings.',
                    initiative_description: 'On August 5th 2020, the morning after the blast that took over 200 lives, injured thousands and displaced over 300,000, a group of local neighbors met at an abandoned gas station near Al Roum hospital to take stock of their shattered surroundings. Amid the confusion and chaos, the young group decided to hand out food to the distressed and traumatized neighborhood. This small gesture of solidarity would soon evolve into a fully operational relief center, providing food and medication, in addition to sealing homes. After the initial relief phase, Nation Station is now making the transition into a self-sustainable community hub.',
                    initiative_linked_in_url: '',
                    initiative_website_url: 'https://thenationstation.org/',
                    initiative_instagram_url: '',
                    initiative_facebook_url: '',
                    initiative_img_url:'https://ardkon.s3.eu-central-1.amazonaws.com/profile-members/hero-to-back-1630425543959.png'
                },   {
                    initiative_id: '4644c74b-8afc-4a9a-b355-e89d7c06914b',
                    initiative_dialogue: false,

                    initiative_title: 'ardkon.com',
                    initiative_small_description: 'turning public demands into innovative assets…',
                    initiative_linked_in_url: '',
                    initiative_website_url: 'https://ardkon.com',
                    initiative_instagram_url: '',
                    initiative_facebook_url: '',
                    initiative_img_url:'https://ardkon.s3.eu-central-1.amazonaws.com/profile-members/hero-to-back-1634321462872.png'
                },   {
                    initiative_id: '4644c74b-8afc-4a9a-b355-e89d7c06124b',
                    initiative_dialogue: false,

                    initiative_title: 'Animals Lebanon',
                    initiative_small_description: 'Animals Lebanon s mission is to inspire the nation to understand and  care about all animals. Focusing education, legislation, and animal care, Animals Lebanon is now the most recognized animal welfare organization in Lebanon.',
                    initiative_description:'Founded 9 September 2008 Registered Charity #1036 Ministry of Finance #2674180',
                    initiative_linked_in_url: '',
                    initiative_website_url: 'www.animalslebanon.org',
                    initiative_instagram_url: '',
                    initiative_facebook_url: '',
                    initiative_img_url:'https://ardkon.s3.eu-central-1.amazonaws.com/profile-members/hero-to-back-1630558718974.png'
                },    {
                    initiative_id: '36f689cc-4ec6-4401-960e-259fbe0439c6',
                    initiative_dialogue: false,

                    initiative_title: 'Home of Cine-Jam',
                    initiative_small_description: 'Home of Cine-Jam is an association for Humanitarian Arts. Its main\nobjective is to document human emotions through Art and to inspire,\ninduce social and emotional healing through authentic short films or\nseries.',
                    initiative_description:'Home of Cine-Jam is an artistic, educational platform that\npromotes intuitive art and most of all authentic creations.\nWe are headed towards a generation consumed by technology and\nrobotization is on its way',
                    initiative_linked_in_url: '',
                    initiative_website_url: 'https://homeofcinejam.com/',
                    initiative_instagram_url: '',
                    initiative_facebook_url: '',
                    initiative_img_url:'https://ardkon.s3.eu-central-1.amazonaws.com/profile-members/hero-to-back-1634978360067.png'
                },  

            ]
        }
    },
    methods: {
        goToLinked(){
                //window.location.href = "https://api.linkedin.com/oauth/v2/authorization?redirect_uri=http://localhost:8081/auth/initiative/LiBeirut/&response_type=code&state=bitchplease&grant_type=authorization_code&scope=r_liteprofile+r_emailaddress&client_id=78n76wlujmwyxc"
                
                this.$router.push('../auth/initiative/LiBeirut')
                console.log('hii')
            },
    },
    computed:{
        
        /*initiatives(){
            return store.state.partners.active_campaign.initiatives
        }
        */
    }
}
</script>
<style>
.initiatives{
    max-height: 36vh;
    overflow-y: scroll;
}
</style>