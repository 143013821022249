<template>
<v-card class="ma-1" @click="setStream" :href="video.bc_link">
<v-row no-gutters>
   
    <v-col cols="8"> 
        <v-card-text class="caption font-weight-bold">{{video.bc_name}}
        <br> 
        <span class="primary--text">{{video.stream_type}}</span>  
        </v-card-text>
    </v-col>
    <v-col cols="4" class="my-auto">
        <v-btn x-small color="primary" outlined  :href="video.bc_url" target="_blank">
visit
        </v-btn>
       
    </v-col>
</v-row>
</v-card>
</template>
<script>
import store from '@/store'
export default {
    name: 'video',
    props: {
        video: Object
    },
    methods:{
        setStream(){
            store.dispatch('streaming/setStream', this.video)

        }
    }
}
</script>