<template>
  <div class=" info-rotation">

    <v-app-bar  dense color="transparent" elevation="0" >
      <v-img
    
    class="mr-3"
    src="https://ard-platform.s3.eu-central-1.amazonaws.com/profile-members/hero-to-back-1627220000884.png"
    max-height="40"
    max-width="40"
    contain
  ></v-img>
      <span class="primary--text font-weight-bold" style="font-size: 3vh">
            Li Beirut لبيروت
      </span>
      <v-btn
              small
              icon

        class="my-auto text-lowercase" color="primary" @click="showBanner = true"
            >
            <v-icon small
       >
                mdi-alert-circle-outline
            
          </v-icon>
            </v-btn>
          <v-spacer>

          </v-spacer>
          <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-app-bar-nav-icon v-bind="attrs"
          v-on="on" class="font-weight-bold d-sm-flex d-md-none d-lg-none"></v-app-bar-nav-icon>

     
      </template>

      <v-card>
        <v-list>
          <v-list-item  target="_blank"

href="https://www.facebook.com/ardkoncom-106260317399870">
            <v-list-item-avatar>
              <v-icon size="24px">
            {{ icons[0] }}
          </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
             
              <v-list-item-subtitle>Facebook</v-list-item-subtitle>
            </v-list-item-content>

          
          </v-list-item><v-list-item  target="_blank"
          href="https://www.linkedin.com/company/71852371/">
            <v-list-item-avatar>
              <v-icon size="24px">
            {{ icons[1] }}
          </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
             
              <v-list-item-subtitle>Linked In</v-list-item-subtitle>
            </v-list-item-content>

          
          </v-list-item> <v-list-item
          target="_blank"
          href="https://www.instagram.com/ardkon_com/"
          ><v-list-item-avatar>
              <v-icon size="24px">
            {{ icons[2] }}
          </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
             
              <v-list-item-subtitle>Instagram</v-list-item-subtitle>
            </v-list-item-content>

          
          </v-list-item> <v-list-item  target="_blank"
          href="https://www.clubhouse.com/club/li-beirut"
          ><v-list-item-avatar>
                <v-icon size="24px">$vuetify.icons.custom</v-icon>
         
      
            </v-list-item-avatar>

            <v-list-item-content>
             
              <v-list-item-subtitle>Club House</v-list-item-subtitle>
            </v-list-item-content>

          
          </v-list-item>
          <v-list-item  @click="partners_dialog = true">
            <v-btn block color="primary">
              <v-icon size="24px">
            {{ icons[3] }}
          </v-icon>
            </v-btn>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        
      </v-card>
    </v-menu>
<v-dialog v-model = "showBanner" max-width="639">
  <v-img src="https://ardkon.s3.eu-central-1.amazonaws.com/profile-members/hero-to-back-1634218572980.png" height="369" contain></v-img>

</v-dialog>
<div class="d-none d-md-flex d-lg-flex">
  <v-btn
                    target="_blank"

          href="https://www.facebook.com/ardkoncom-106260317399870"
          class="mx-1 gold--text  "
          icon
        >
          <v-icon color="primary" size="24px">
            {{ icons[0] }}
          </v-icon>


        </v-btn>  <v-btn
        target="_blank"
          href="https://www.linkedin.com/company/71852371/"
          class="mx-1 gold--text"
          
          icon
        ><v-icon color="primary" size="24px">
            {{ icons[1] }}
          </v-icon>

        </v-btn><v-btn
        target="_blank"
          href="https://www.instagram.com/ardkon_com/"
          
          class="mx-1 gold--text"
          icon
          
        ><v-icon  color="primary" size="24px">
            {{ icons[2] }}
          </v-icon>
         


        </v-btn><v-btn
        target="_blank"
          href="https://www.clubhouse.com/club/li-beirut"
          
          class="mx-1 gold--text"
          icon
        > <v-icon color="primary" >$vuetify.icons.custom</v-icon>
         


        </v-btn>
        
        <v-tooltip
          v-model="show"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="partners_dialog = true"

            >
            <v-icon color="primary" size="36px">
            {{ icons[3] }}
            
          </v-icon>
            </v-btn>
          </template>
          <span>partners</span>
        </v-tooltip>
       

</div>
         
        </v-app-bar>


    <v-dialog max-width="639" v-model="partners_dialog">
      <v-card color="primary">
        <v-card-title class="text-lowercase white--text">Campaign Partners</v-card-title>
        <Partners></Partners>

       
      </v-card>
    </v-dialog>

      <v-row   no-gutters align="center">
        <v-col md="6"  cols="12"  class="first-section">
        
        <MapComponent class="first-section-body"></MapComponent>

  </v-col>
        <v-col md="3"  cols="6" class="first-section">
<InfoComponent class="first-section-body"></InfoComponent>
    </v-col>
   
   
    <v-col cols="6" md="3"  class="first-section">
      <Initiatives class="first-section-body"></Initiatives>
    </v-col>
    <v-col cols="6" md="3"  class="first-section">
      <Venues class="first-section-body"></Venues>
    </v-col>
    <v-col cols="6" md="3"   class="first-section">
     <Ledger class="first-section-body"></Ledger>
    </v-col>
   
    <v-col cols="8" md="4"  class="first-section">
      <MainStream class="first-section-body"></MainStream>
    </v-col>

    
    <v-col cols="4" md="2" class="first-section">
      <Streams class="first-section-body"></Streams>

    </v-col>
    
    
    
    

  </v-row>
 
</div>
</template>

<script>
import Partners from '../components/Partners/Partners'
import store from '@/store'
import MapComponent from '../components/Map/MapComponent'
import InfoComponent from '../components/Info/InfoComponent'
import Initiatives from '../components/Initiatives/Initiatives'
import Venues from '../components/Venues/Venues'
import Ledger from '../components/Ledger/Ledger'
import MainStream from '../components/Stream/MainStream'
import Streams from '../components/Stream/Streams'
import sticker from '@/assets/sticker.jpeg'

import clubhouse from '@/assets/clubhouse.svg'
// import this to use default icons for buttons
import "vue-pdf-app/dist/icons/main.css";

export default {
  name: 'ActionPage',

  components: {
    Partners,
    MapComponent,
    InfoComponent,
    Initiatives,
    Venues,
    Ledger,
    MainStream,
    Streams,
    
    
  },
  data(){
    return {
      icons: [
        'mdi-facebook',
        'mdi-linkedin',
        'mdi-instagram',
        'mdi-handshake-outline'
        
      ],
      clubhouse:clubhouse,
      sticker:sticker,
      partners_dialog: false,
      drawer: false,
      partners_tool: false,
      showBanner: false
    }
  },
  created(){
  },
  mounted() {
    document.title = ' LI BEIRUT لبيروت'
    store.dispatch('partners/getPartners',{action_id: '4644c74b-8afc-4a9a-b355-e89d7c06914b'})
    store.dispatch('partners/getInitiatives',{action_id: '4644c74b-8afc-4a9a-b355-e89d7c06914b'})
    store.dispatch('venues/getActs',{campaign_id: '4644c74b-8afc-4a9a-b355-e89d7c06914b'})
    store.dispatch('streaming/getStreams',{campaign_id: '4644c74b-8afc-4a9a-b355-e89d7c06914b'})
    store.dispatch('donations/getDonationsCampaign',{campaign_id: '4644c74b-8afc-4a9a-b355-e89d7c06914b'})
  }
}
</script>
<style>
.first-section{
  height:44vh;
overflow: hidden;}
.first-section-body{
  margin:3px;
}
@media only screen and (max-width: 600px) {
  
}
.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

@media screen and (max-width: 767px) {
  .grid-base {
    width: 100vw;
    height: 100vh;
  }
  .first-section-body-rotate{
 }
  .upped-panel {
    position: absolute;
    left: auto;
    top: auto;
    right: 0%;
    bottom: 0%;
    width: 12%;
    height: 90vh;
    padding-right: 0px;
    padding-bottom: 0px;
  }

  .lower-panel {
    position: absolute;
    left: 0%;
    top: auto;
    right: auto;
    bottom: 0%;
    width: 20%;
    height: 90vh;
    padding-right: 0px;
    padding-bottom: 0px;
  }

  .left-tabs {
    width: 68%;
    height: 10vh;
    margin-right: 12%;
    margin-left: auto;
    padding-right: 0px;
    padding-bottom: 0px;
  }

  .grid {
    width: 62vw;
    height: 90vh;
    margin-right: auto;
    margin-left: 22%;
    grid-template-areas: "Area-1 Area-1"
      "Area-2 Area-3";
  }
}

@media screen and (max-width: 479px) {
  .grid {
    margin-left: 23%;
    padding-top: 14px;
  }
}

@media screen and (max-width: 767px) {
  #w-node-_367fa891-e047-647e-4f23-3bf2059428b8-ec955ae0 {
    grid-area: Area-3;
    overflow:hidden;
    transform:rotate(90deg)

  }

  #w-node-ca13384f-a8d4-a164-506b-ebbc6fc6cdfc-ec955ae0 {
    grid-area: Area-2;
    transform:rotate(90deg)

  }

  #w-node-_4d045083-cdde-7814-413c-0f07e5aea663-ec955ae0 {
    grid-area: Area-1;
    transform:rotate(90deg)

  }
}

@media screen and (max-width: 767px) {
  #w-node-_367fa891-e047-647e-4f23-3bf2059428b8-ec955ae0 {
    -ms-grid-row: 2;
    -ms-grid-column: 2;
  }

  #w-node-ca13384f-a8d4-a164-506b-ebbc6fc6cdfc-ec955ae0 {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
  }

  #w-node-_4d045083-cdde-7814-413c-0f07e5aea663-ec955ae0 {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
  }
}
</style>