<template>
<div>
    <v-card @click="partner.partner_dialogue = true" color="255,255,255,0.36)" class="card-partner ma-1">
<v-row no-gutters>
    <v-col cols="12" >
        <v-img contain  :src="partner.partner_img_url"></v-img>
    </v-col>
    <v-col class="my-auto image-card-partner" style="min-height:9rem;max-height:9rem"  md="12">
        <v-card-text class="caption font-weight-bold ">
            {{partner.partner_title}}<br>
        
            {{partner.partner_small_description}}

        </v-card-text>
    </v-col>
    <v-col  cols="12">
        <v-card-text>
            <v-btn
            target="_blank"

            :href="partner.partner_url"
            block class="mx-1" small color="primary" outlined>visit</v-btn>

        </v-card-text>
    </v-col>
</v-row>
</v-card>
<v-dialog max-width="420" v-model="partner.partner_dialogue">
    <v-card class=" ma-1">
        <v-card-text>
<v-row>
    <v-col cols="4" class="my-auto ">
        <v-img  height="100%" width="100%" :src="partner.partner_img_src"></v-img>
    </v-col>
    <v-col class="my-auto " md="8">
        <v-card-text class="text-caption font-weight-bold ">
            {{partner.partner_title}}
            
        </v-card-text>
        <v-card-text>
            {{partner.partner_small_description}}

        </v-card-text>
    </v-col>

</v-row>
<v-row>
    <v-col cols="12">

    </v-col>
</v-row>
</v-card-text>

</v-card>
</v-dialog>
</div>

</template>
<script>
export default {
    name: 'partner',
    props:{
        partner: Object
    }
}
</script>
<style>
.card-partner{
    overflow-y:hidden;
    background-color: rgba(255,255,255,1)!important;
    border-radius: 0px!important;
  transition: transform .2s; /* Animation */
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 3px 10px 0 rgba(0, 0, 0, 0.12);
    background-color:rgba(	226,	229,	232, 0.63);
    overflow:hidden;
    min-height: 25rem;
    max-height: 25rem;

}
.card-partner:hover{
    transform: scale(0.9);
}


</style>